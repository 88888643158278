const routes = [
    {
      /*
        Set Up router for guests and 
        landing page display vue file
      */
      path: '/',
      name: 'PreEntry',
      meta: { 
        requiresAuth: false
      }
    },
    {
      /*
        Set Up router for guests and 
        landing page display vue file
      */
      path: '/login',
      name: 'HomeLogin',
      meta: { 
        requiresAuth: false
      },
      component: () => import('@/views/Login/config.vue'),
    }
  ]
  
  export default routes