export default {
  data() {
    return {
      time: 0,
      timeoutID: 0,
      // dialogKillSession: false,
      screenWidth: 0,
      texts: "",
    };
  },
  beforeMount() {
    //Initialize the texts of the platform
    this.texts = FILE.killSessionTexts[this.selectLanguage];
  },
  created() {
    document.addEventListener("mousemove", this.resetTimer, false);
    document.addEventListener("mousedown", this.resetTimer, false);
    document.addEventListener("keypress", this.resetTimer, false);
    document.addEventListener("DOMMouseScroll", this.resetTimer, false);
    document.addEventListener("mousewheel", this.resetTimer, false);
    document.addEventListener("touchmove", this.resetTimer, false);
    document.addEventListener("MSPointerMove", this.resetTimer, false);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    document.addEventListener("mousemove", this.resetTimer, false);
    document.addEventListener("mousedown", this.resetTimer, false);
    document.addEventListener("keypress", this.resetTimer, false);
    document.addEventListener("DOMMouseScroll", this.resetTimer, false);
    document.addEventListener("mousewheel", this.resetTimer, false);
    document.addEventListener("touchmove", this.resetTimer, false);
    document.addEventListener("MSPointerMove", this.resetTimer, false);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    startTimer() {
      // wait 30 minutes before calling goInactive  
      this.timeoutID = window.setTimeout(this.goInactive, 1800000);
    },
    resetTimer(e) {
      //reset time of token
      window.clearTimeout(this.timeoutID);
      this.goActive();
    },
    goInactive() {

      if (this.$route.name !== 'HomeLogin') {
        this.$store.commit('setDialogKillSession',true)
      }
    },
    goActive() {
      //initialize timeroutId: 0
      this.startTimer();
    },
    exit() {
      this.$store.commit('setDialogKillSession',false)
      this.$router.push("/");
    },
    close(){
      // this.$emit('close');
      this.$store.commit('setDialogKillSession',false)
    }

  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.killSessionTexts[this.selectLanguage];
      }
    },
  },
  computed: {
    text() {
      return this.$store.state.text;
    },
    color() {
      return this.$store.state.color;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogKillSession(){
      return this.$store.state.dialogKillSession;      
    }
  },
};