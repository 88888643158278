var texts = {
  en: {
    user: {
      vendor:{
        title:'User profile',
        statusActive:'Active user',
        statusInactive:'user blocked',
        noAssignment:'no assignment',
        changeStatusTexts:{
          title:'Change confirmation',
          activeToInactive:{
            description:"You are about to change the user's status to blocked. Are you sure to take that action?",
            note:'Note: Items attached to the user will go inactive, and completed orders will continue their current planned course.',
            chkLabel:'I agree to block the user and everything related to him.',
          },
          inactiveToActive:{
            description:"You are about to change the user's status to unblocked. Are you sure to take that action?",
            note:'Note: Items attached to the user will become active, and completed orders will continue their current planned course.',
            chkLabel:'I agree to unblock the user and everything related to him.',
          },
          cancel:'Cancel',
          confirm:'Confirm'
      },
        
      },
      admin:{
        title:'Admin profile'
      }
    },
    buttons:{
      delete:'Delete',
      changeStatus:'Change status',
      toReturn:'Return'

    }
  },
  sp: {
    user: {
      vendor:{
        title:'Perfil de usuario',
        statusActive:'Usuario activo',
        statusInactive:'Usuario bloqueado',
        noAssignment:'Sin asignación',
        changeStatusTexts:{
          title:'Confirmación de cambio',
          activeToInactive:{
            description:'Estás a punto de cambiar el estado del usuario a bloqueado. ¿Estás seguro de realizar esa acción?',
            note:'Nota: Los artículos adjuntos al usuario pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
            chkLabel:'Estoy de acuerdo con bloquear el usuario y todo lo relacionado a él.',
          },
          inactiveToActive:{
            description:'Estás a punto de cambiar el estado del usuario a desbloqueado. ¿Estás seguro de realizar esa acción?',
            note:'Nota: Los artículos adjuntos al usuario pasarán a estado activo, y los pedidos concretados continuarán su curso actual previsto.',
            chkLabel:'Estoy de acuerdo con desbloquear el usuario y todo lo relacionado a él.',
          },
          cancel:'Cancelar',
          confirm:'Confirmar'
      },
      },
      admin:{
        title:'Perfil del administrador'
      }
    },
    buttons:{
      delete:'Eliminar',
      changeStatus:'Cambiar estatus',
      toReturn:'Regresar'
    }
  }
};

export default texts;