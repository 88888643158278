var texts = {
  en: {
    table: {
      //  headers: ["Name","Email","Phone","Creation date","Status","Verified","Actions",
      // ],
      headers: [
        { text: "Name", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Email", value: 'sEmail', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Phone", value: 'sPhoneNumber', cellClass: 'mon f-s14 color000000 text-center', class: 'f-s14 text-center' },
        { text: "Creation date", value: 'tCreatedAtView', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Status", value: 'bBlockStatus', class: 'f-s14' },
        { text: "Verified", value: 'bVerified', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Actions", value: 'actions', align: 'center',cellClass:'nolinebreak', class: 'f-s14' },
      ],
      actions: {
        labels: {
          textEdit: 'Edit',
          textActivate: 'Enabled',
          textDeactivate: 'Disabled',
          textRecoveryPassword: 'Reset password',
          textDelete: 'Delete',
          textDetails: 'Detail'
        }
      },
      status: {
        textActivate: 'Enabled',
        textDeactivate: 'Disabled',
        actives: 'Active',
        inactives: 'Inactive',
        verified: 'Verified',
        Unverified: 'Unverified'

      },
    },
    pagination: {
      paginationRowPerPage: 'Rows per page',
      of: 'of'
    },
    dialogDelete: {
      textTitle: 'Customers',
    },
    dialogEdit: {
      title: 'Edit customer',
      description: 'Edit the information to display for the customer in the list.',
      placeholders: {
        latitude: 'Capture latitude',
        longitude: 'Capture longitude',
        blueBook: 'Capture the id of the blue book',
        description: 'Capture the description',
        email: 'Capture email',
        address: 'Capture the address',
        city: 'Capture the city',
        zipCode: 'Capture the zip code',
        name: 'Capture the name',
        license: 'Capture the PACA license number',
        phoneNumber: 'Capture the phone number',
        state: 'Select the state',
        taxPayerId: 'Capture the tax payer id',
        country: 'Select the country'
      },
      buttons: {
        edit: 'Edit',
        cancel: 'Cancel'
      },
      labels: {
        latitude: 'Latitude',
        longitude: 'Longitude',
        blueBook: 'Blue book Id',
        description: 'Description',
        email: 'Email',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',
        name: 'Name',
        license: 'PACA license',
        phoneNumber: 'Phone number',
        state: 'State',
        taxPayerId: 'Tax payer id',
        country: 'Country'
      },
    },
    tooltip: {
      search: 'Search'
    },
    tabsItems: {
      actives: 'Actives',
      inactives: 'Inactives',
    },
    customerDetails: {
      itemsTab: [
        'Profile', 'Users', 'Warehouses', 'Documents',
      ],
      profileDetail: {
        userDetail: {
          userData: 'User data',
          name: 'Name',
          lastName: 'Last name',
          mail: 'Mail',
          position: 'Position',
          phone: 'Phone',
          delete: 'Delete',
          toReturn: 'Return',
          changePassword: 'Change password',
          deleteTexts: {
            title: 'Remove buyer',
            description: 'Are you sure to delete the buyer? By performing this action it cannot be reversed ',
            note: 'Note: Items attached to the buyer will go into inactive status, and completed orders will continue their current intended course.',
            chkLabel: 'I agree to remove the buyer and everything related to him.',
            cancel: 'Cancel',
            delete: 'Delete'
          },
          verifiedCustomer: 'Customer verified',
          customerNotVerified: 'Customer restricted',
          toRestrict: 'Restrict',
          verified: 'Verified',
          toRestrictTexts: {
            title: 'Customer verification',
            description: "You are about to restrict a customer's activity. Are you sure to take that action?",
            descriptionVerified: "You are about to verified a customer's activity. Are you sure to take that action?",
            note: 'Note: The customer will be able to carry out transactions within the platform until it is verified again.',
            noteVerified: 'Note: The buyer will be able to carry out transactions within the platform.',
            chkLabel: 'I agree to restrict the user and everything related to him.',
            chkLabelVerified: 'I agree to verify the user and everything related to him.',
            cancel: 'Cancel',
            confirm: 'Confirm'
          },
          activeCustomer: 'Active customer',
          inactiveCustomer: 'Inactive customer',
          customerBlocked: 'Customer blocked',
          customerUnlocked: 'Customer unlocked',
          toBlock: 'Block',
          toUnlock: 'Unlock',
          changeStatusTexts: {
            title: 'Confirmation of change',
            description: 'You are about to change the status of the vendor to blocked. Are you sure to take that action? ',
            descriptionUnlock: 'You are about to enable the buyer. Are you sure to take that action? ',
            note: "Note: The buyer's activity within the platform will be blocked including their access to it, and orders will continue their current planned course.",
            noteUnlock: "Note: The buyer's activity within the platform will be restored, including their access to it.",
            chkLabel: 'I agree to block the user and everything related to him.',
            chkLabelUnlock: 'I agree to enable the user and everything related to it.',
            cancel: 'Cancel',
            confirm: 'Confirm'
          },
          modifyCreditAuthorization:{
            title: 'Modify credit authorization',
            description: 'You are about to credit the buyer. Are you sure you take that action?',
            noteAuthorize: 'Note: The buyer will be able to select PROPLAT credit as a payment method for their purchases.',
            noteRevoke: 'Note: The buyer will not be able to select PROPLAT credit as a payment method for their purchases.',
            chkLabel: "I agree to change the buyer's credit authorization.",
            cancel: 'Cancel',
            confirm: 'Confirm'
          },
          revoke:'Revoke',
          authorize: 'Authorize'
        },
        enterpriseDetail: {
          companyData: 'Company data',
          companyName: 'Company name',
          legalAddressCompany: 'Legal address of the company',
          address: 'Address',
          postalCode: 'Postal code',
          companyEmail: 'Company mail',
          companyPhone: 'Company phone',
          PACALicenseNumber: 'PACA license number',
          blueBookNumber: 'Blue book number',
          RFC: 'Federal taxpayer registration',
          enterpriseDescription: 'Company description',
          proplatCreditAuthorization:'PROPLAT credit authorization',
          theCompany:'This company',
          creditAuthorization:'credit authorization',
          bHaveCreditTrue:'has',
          bHaveCreditFalse:"doesn't have",
          btnModify:'Modify'
        }
      },
      usersDetail: {
        headers: [
          { text: "Name", value: 'sName', class: 'global-header-table' },
          // {text:"Last name", value:'sLastname', class:'global-header-table'},
          { text: "Email", value: 'sEmail', class: 'global-header-table' },
          { text: "Position", value: 'sPositionName', class: 'global-header-table' },
          { text: "Phone", value: 'sPhoneNumber', class: 'global-header-table' },
          { text: "Status", value: 'bPlatformAccess', class: 'global-header-table text-center' },
          { text: "Actions", value: 'actions', align: 'center', class: 'global-header-table text-center ' },
        ],
        deleteTexts: {
          title: 'Delete user',
          description: 'Are you sure to delete the user? By performing this action it cannot be reversed ',
          note: 'Note: Items attached to the user will go inactive, and completed orders will continue their current planned course.',
          chkLabel: 'I agree to remove the user and everything related to him.',
          cancel: 'Cancel',
          delete: 'Delete'
        },
        status: {
          active: 'Active',
          inactive: 'Inactive'
        },
        detailByUser: {
          userDetail: 'User detail'
        }
      },
      warehousesDetail: {
        DetailsWarehouse: {
          description: 'Description',
          warehouseDetail: 'Warehouse detail',
          warehouseName: 'Warehouse name',
          address: 'Address',
          country: 'Country',
          state: 'State',
          city: 'City',
          postalCode: 'Postal code',
          warehousePhone: 'Warehouse phone',
          warehouseManager: 'Warehouse manager',
          warehouseContactEmail: 'Warehouse contact email',
          watch:'Watch',
          delete:'Delete'
        },
        delete: {
          title: 'Delete warehouse',
          description: 'Are you sure to delete the warehouse? By performing this action it cannot be reversed',
          note: 'Note: Items attached to the warehouse will go inactive, and completed orders will continue their current intended course.',
          chkLabel: 'I agree to remove the warehouse and everything related to him.',
          cancel: 'Cancel',
          delete: 'Delete'
      }
      },
      documentsDetail: {
        headers: [
          { text: "Name", value: 'sCustomerFileTypeName',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
          { text: "Company document", value: 'sFileKey',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
          { text: "Actions", value: 'actions', align: 'center',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
        ],
        delete: {
          title: 'Delete document',
          description: 'Are you sure to delete the document? By performing this action it cannot be reversed ',
          note: '',
          chkLabel: '',
          cancel: 'Cancel',
          delete: 'Delete'
      }
      }

    },
    searchbar:{
      search:'Customer name...'
    },
    informationNotYetRegistered:'Information not yet registered'

  },
  sp: {
    table: {
      headers: [
        { text: "Nombre", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Correo electrónico", value: 'sEmail', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Teléfono", value: 'sPhoneNumber', align: 'center',cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Fecha de creación", value: 'tCreatedAtView', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Estado", value: 'bBlockStatus', align: 'center', class: 'f-s14' },
        { text: "Verificado", value: 'bVerified', align: 'center', cellClass: 'mon f-s14 color000000', class: 'f-s14' },
        { text: "Acciones", value: 'actions', align: 'center',cellClass:'nolinebreak', class: 'f-s14' },
      ],
      actions: {
        labels: {
          textEdit: 'Editar',
          textActivate: 'Activar',
          textDeactivate: 'Desactivar',
          textRecoveryPassword: 'Recuperar contraseña',
          textDelete: 'Eliminar',
          textDetails: 'Detalle'
        }
      },
      status: {
        textActivate: 'Activado',
        textDeactivate: 'Desactivado',
        actives: 'Activo',
        inactives: 'Inactivo',
        verified: 'Verificado',
        Unverified: 'No verificado'
      },
    },
    pagination: {
      paginationRowPerPage: 'Filas por página',
      of: 'de'
    },
    dialogDelete: {
      textTitle: 'Compradores',
    },
    dialogEdit: {
      title: 'Editar comprador',
      description: 'Edite la información a mostrar del subadministrador en el listado.',
      placeholders: {
        latitude: 'Capture la latitud',
        longitude: 'Capture la longitud',
        blueBook: 'Capture el id del blue book',
        description: 'Capture la descripción',
        email: 'Capture el correo eletrónico',
        address: 'Capture la dirección',
        city: 'Capture la ciudad',
        zipCode: 'Capture el código postal',
        name: 'Capture el nombre',
        license: 'Capture el número de lincencia de PACA',
        phoneNumber: 'Capture el número telefónico',
        state: 'Seleccione el estado',
        taxPayerId: 'Capture el tax payer id',
        country: 'Seleccione el país'
      },
      buttons: {
        edit: 'Editar',
        cancel: 'Cancelar'
      },
      labels: {
        latitude: 'Latitud',
        longitude: 'Longitud',
        blueBook: 'Id blue book',
        description: 'Descripción',
        email: 'Correo electrónico',
        address: 'Dirección',
        city: 'Ciudad',
        zipCode: 'Código postal',
        name: 'Nombre',
        license: 'Lincencia PACA',
        phoneNumber: 'Número telefónico',
        state: 'Estado',
        taxPayerId: 'Id tax payer',
        country: 'País'
      }
    },
    tooltip: {
      search: 'Buscar'
    },
    tabsItems: {
      actives: 'Activos',
      inactives: 'Inactivos',
    },
    customerDetails: {
      itemsTab: [
        'Perfil', 'Usuarios', 'Almacenes', 'Documentos',
      ],
      profileDetail: {
        userDetail: {
          userData: 'Datos del usuario',
          name: 'Nombre',
          lastName: 'Apellido',
          mail: 'Correo electrónico',
          position: 'Posición',
          phone: 'Teléfono',
          delete: 'Eliminar',
          toReturn: 'Regresar',
          changePassword: 'Cambiar contraseña',
          deleteTexts: {
            title: 'Eliminar comprador',
            description: '¿Estás seguro de eliminar el comprador? Al realizar esta acción no podrá revertirse',
            note: 'Nota: Los artículos adjuntos al comprador pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
            chkLabel: 'Estoy de acuerdo con eliminar al comprador y todo lo relacionado a él.',
            cancel: 'Cancelar',
            delete: 'Eliminar'
          },
          verifiedCustomer: 'Comprador verificado',
          customerNotVerified: 'Comprador restringido',
          toRestrict: 'Restringir',
          verified: 'Verificar',
          toRestrictTexts: {
            title: 'Verificación de comprador',
            description: 'Estás a punto de restringir la actividad de un comprador. ¿Estás seguro de realizar esa acción?',
            descriptionVerified: 'Estás a punto de verificar la actividad de un comprador. ¿Estás seguro de realizar esa acción?',
            note: 'Nota: El comprador no podrá realizar transacciones dentro de la plataforma hasta que sea verificado nuevamente.',
            noteVerified: 'Nota: El comprador podrá realizar transacciones dentro de la plataforma.',
            chkLabel: 'Estoy de acuerdo con restringir el usuario y todo lo relacionado a él.',
            chkLabelVerified: 'Estoy de acuerdo con verificar el usuario y todo lo relacionado a él.',
            cancel: 'Cancelar',
            confirm: 'Confirmar'
          },
          customerBlocked: 'Comprador bloqueado',
          customerUnlocked: 'Comprador habilitado',
          toBlock: 'Bloquear',
          toUnlock: 'Habilitar',
          changeStatusTexts: {
            title: 'Confirmación de cambio',
            description: 'Estás a punto de cambiar el estado del comprador a bloqueado. ¿Estás seguro de realizar esa acción?',
            descriptionUnlock: 'Estás a punto de habilitar el comprador. ¿Estás seguro de realizar esa acción?',
            note: 'Nota: La actividad del comprador dentro de la plataforma se bloqueará incluído su acceso a la misma, y los pedidos continuarán su curso actual previsto.',
            noteUnlock: 'Nota: La actividad del comprador dentro de la plataforma se reestablecerá incluído su acceso a la misma.',
            chkLabel: 'Estoy de acuerdo con bloquear el usuario y todo lo relacionado a él.',
            chkLabelUnlock: 'Estoy de acuerdo con habilitar al usuario y todo lo relacionado a él.',
            cancel: 'Cancelar',
            confirm: 'Confirmar'
          },
          modifyCreditAuthorization:{
            title: 'Modificar autorización de crédito',
            description: 'Estás a punto de la autorización de crédito de el comprador. ¿Estás seguro de realizar esa acción?',
            noteAuthorize: 'Nota: El comprador podrá seleccionar crédito PROPLAT como metodo de pago para sus compras.',
            noteRevoke: 'Nota: El comprador no podrá seleccionar crédito PROPLAT como metodo de pago para sus compras.',
            chkLabel: 'Estoy de acuerdo con cambiar la autorización de crédito de el comprador.',
            cancel: 'Cancelar',
            confirm: 'Confirm'
          },
          revoke:'Revocar',
          authorize: 'Autorizar'
        },
        enterpriseDetail: {
          companyData: 'Datos de la empresa',
          companyName: 'Nombre de la empresa',
          legalAddressCompany: 'Dirección legal de la empresa',
          address: 'Dirección',
          postalCode: 'Código postal',
          companyEmail: 'Correo de la empresa',
          companyPhone: 'Teléfono de la empresa',
          PACALicenseNumber: 'Número de licencia PACA',
          blueBookNumber: 'Número de registro Blue book',
          RFC: 'RFC',
          enterpriseDescription: 'Descripción de la empresa',
          proplatCreditAuthorization:'Autorización de crédito PROPLAT',
          theCompany:'La empresa',
          creditAuthorization:'con autorización de crédito',
          bHaveCreditTrue:'si cuenta',
          bHaveCreditFalse:'no cuenta',
          btnModify:'Modificar'



        }
      },
      usersDetail: {
        headers: [
          { text: "Nombre", value: 'sAllName', class: 'global-header-table' },
          // {text:"Apellido", value:'sLastname', class:'global-header-table'},
          { text: "Correo electrónico", value: 'sEmail', class: 'global-header-table' },
          { text: "Posición", value: 'sPositionName', class: 'global-header-table' },
          { text: "Teléfono", value: 'sPhoneNumber', class: 'global-header-table' },
          { text: "Estado", value: 'bPlatformAccess', class: 'global-header-table text-center' },
          { text: "Acciones", value: 'actions', align: 'center', class: 'global-header-table text-center ' },

        ],
        deleteTexts: {
          title: 'Eliminar usuario',
          description: '¿Estás seguro de eliminar el usuario? Al realizar esta acción no podrá revertirse',
          note: 'Nota: Los artículos adjuntos al usuario pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
          chkLabel: 'Estoy de acuerdo con eliminar al usuario y todo lo relacionado a él.',
          cancel: 'Cancelar',
          delete: 'Eliminar'
        },
        status: {
          active: 'Activo',
          inactive: 'Inactivo'
        },

        detailByUser: {
          userDetail: 'Detalle del usuario'
        }
      },
      warehousesDetail: {
        DetailsWarehouse: {
          description: 'Descripción',
          warehouseDetail: 'Detalle de almacén',
          warehouseName: 'Nombre del Almacén',
          address: 'Dirección',
          country: 'País',
          state: 'Estado',
          city: 'Ciudad',
          postalCode: 'Código postal',
          warehousePhone: 'Teléfono del almacén',
          warehouseManager: 'Responsable del almacén',
          warehouseContactEmail: 'Correo de contacto del almacén',
          watch:'Ver',
          delete:'Eliminar'
          
        },
        delete: {
          title: 'Eliminar almacén',
          description: '¿Estás seguro de eliminar el almacén? Al realizar esta acción no podrá revertirse',
          note: 'Nota: Los artículos adjuntos al almacén pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
          chkLabel: 'Estoy de acuerdo con eliminar el almacén y todo lo relacionado a él.',
          cancel: 'Cancelar',
          delete: 'Eliminar'
      }
      },
      documentsDetail: {
        headers: [
          { text: "Nombre", value: 'sCustomerFileTypeName', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
          { text: "Documento de la empresa", value: 'sFileKey',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
          { text: "Acciones", value: 'actions', align: 'center', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
        ],
        delete: {
          title: 'Eliminar documento',
          description: '¿Estás seguro de eliminar el documento? Al realizar esta acción no podrá revertirse',
          note: '',
          chkLabel: '',
          cancel: 'Cancelar',
          delete: 'Eliminar'
      }
      }

    },
    searchbar:{
      search:'Nombre de comprador...'
    },
    informationNotYetRegistered:'Información no registrada aún'

  }
};

export default texts;