var texts = {
  en: {
    title: 'Change Password',
    description:'An email will be sent to the user with the proper instructions to retrieve their password.',
    cancel: 'Cancel',
    sendRecovery: 'Send token recovery',
  },
  sp: {
    title: 'Cambiar contraseña',
    description:'Se enviará un correo electrónico al solicitante con las instrucciones adecuadas para recuperar su contraseña.',
    cancel: 'Cancelar',
    sendRecovery: 'Enviar recuperación',
  }
};

export default texts;