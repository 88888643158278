var texts = {
  en: {
    table: {
      headers: [
        {text:"Name", value:'nameFull',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Name", value:'sName',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Last name", value:'sLastname',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Email", value:'sEmail',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Phone", value:'sPhoneNumber',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Status", value:'bPlatformAccess',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Creation date", value:'tCreatedAtView',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Actions", value:'actions',align: 'center',cellClass:'mon f-s14 color000000 nolinebreak',class:'f-s14 text-center'},
      ],
      actions: {
        labels: {
          textEdit: 'Edit',
          textDeactivate: 'Disabled',
          textActivate: 'Enabled',
          textRecoveryPassword: 'Reset password',
          textDelete: 'Delete'
        }
      },
      status: {
        textDeactivate: 'Disabled',
        textActivate: 'Enabled',
      },
      delete: {
        textTitle: 'Administrator',
      }
    },
    pagination: {
      paginationRowPerPage: 'Rows per page',
      of: 'of'
    },
    modalAdd: {
      textTitle: 'New administrator',
      textDescription: 'Capture the administrator information.',
      placeholderName: 'Administrator name',
      placeholderLastname: 'Administrator last name',
      placeholderEmail: 'Administrator email',
      placeholderPhone: 'Administrator phone number',
      buttonAdd: 'Add',
      buttonCancel: 'Cancel'
    },
    modalEdit: {
      textTitle: 'Edit administrator',
      textDescription: 'Edit the information to display for the administrator in the list.',
      placeholderName: 'Administrator name',
      placeholderLastname: 'Administrator last name',
      placeholderEmail: 'Administrator email',
      placeholderPhone: 'Administrator phone number',
      buttonAdd: 'Add',
      buttonCancel: 'Cancel',
      labels: {
        name: 'Name',
        lastName: 'Last name',
        email: 'Email',
        phoneNumber: 'Phone number',
      }
    },
    labels: {
      name: 'Name',
      lastname: 'Last name',
      email: 'Email',
      phoneNumber: 'Phone number'
    },
    tooltip: {
      add: 'Add administrator',
      search: 'Search'
    },
    tabsItems:{
      actives: 'Actives',
      inactives: 'Inactives',
    },
    subadminDetail:{
      title:'Administrator detail',
      name:'Name',
      number:'Number',
      direction:'Direction',
      createDate:'Create date',
      permits:'Administrative permits',
      addPermit:'Add permit',
      withoutPermits:'Without permits',
      actions:'Actions',
      delete:'Delete',
      cancel:'Cancel',
      modify:'Modify',
      save:'Save'

    },
    deleteAdmin: {
      title: 'Delete administrator',
      description: 'Are you sure to delete the admin? By performing this action it cannot be reversed ',
      note: '',
      chkLabel: '',
    },
    searchbar:{
      textButton: 'Add',
      search:'Administrator name...',
      addAdministrator:'Add administrator'
    }
  },
  sp: {
    table: {
      
      headers: [
        {text:"Nombre", value:'nameFull',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Nombre", value:'sName',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Apellido", value:'sLastname',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Correo", value:'sEmail',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Teléfono", value:'sPhoneNumber',cellClass:'mon f-s14 color000000',class:'f-s14'},
        // {text:"Estatus", value:'bPlatformAccess',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Fecha de creación", value:'tCreatedAtView',cellClass:'mon f-s14 color000000',class:'f-s14'},
        {text:"Acciones", value:'actions',align: 'center',cellClass:'mon f-s14 color000000 nolinebreak',class:'f-s14 text-center'},
   
      ],
      actions: {
        labels: {
          textEdit: 'Editar',
          textDeactivate: 'Desactivar',
          textActivate: 'Activar',
          textRecoveryPassword: 'Reestablecer contraseña',
          textDelete: 'Eliminar'
        }
      },
      status: {
        textDeactivate: 'Desactivado',
        textActivate: 'Activado',
      },
      delete: {
        textTitle: 'Administrador',
      }
    },
    pagination: {
      paginationRowPerPage: 'Filas por página',
      of: 'de'
    },

    modalAdd: {
      textTitle: 'Nuevo administrador',
      textDescription: 'Capture la información del administrador.',
      placeholderName: 'Nombre del administrador',
      placeholderLastname: 'Apellido del administrador',
      placeholderEmail: 'Email del administrador',
      placeholderPhone: 'Número telefonico del administrador',
      buttonAdd: 'Agregar',
      buttonCancel: 'Cancelar'
    },
    modalEdit: {
      textTitle: 'Editar administrador',
      textDescription: 'Edite la información a mostrar del administrador en el listado.',
      placeholderName: 'Nombre del administrador',
      placeholderLastname: 'Apellido del administrador',
      placeholderEmail: 'Email del administrador',
      placeholderPhone: 'Número telefonico del administrador',
      buttonAdd: 'Agregar',
      buttonCancel: 'Cancelar',
      labels: {
        name: 'Nombre',
        lastName: 'Apellido',
        email: 'Correo electrónico',
        phoneNumber: 'Numero celular',
      }
    },
    labels: {
      name: 'Nombre',
      lastname: 'Apellidos',
      email: 'Correo electrónico',
      phoneNumber: 'Número telefónico'
    },
    tooltip: {
      add: 'Añadir administrador',
      search: 'Buscar'
    },
    tabsItems:{
      actives: 'Activos',
      inactives: 'Inactivos',
    },
    subadminDetail:{
      title:'Detalle de administrador',
      name:'Nombre',
      number:'Número',
      direction:'Dirección',
      createDate:'Fecha de creación',
      permits:'Permisos administrativos',
      addPermit:'Agregar permiso',
      withoutPermits:'Sin permisos',
      actions:'Acciones',
      delete:'Eliminar',
      cancel:'Cancelar',
      modify:'Modificar',
      save:'Guardar'

    },
    deleteAdmin:{
      title: 'Eliminar administrador',
      description: '¿Estás seguro de eliminar el adminsitrador? Al realizar esta acción no podrá revertirse',
      note: '',
      chkLabel: '',
    },
    searchbar:{
      textButton: 'Agregar',
      search:'Nombre de administrador...',
      addAdministrator:'Agregar administrador'
    }
  }
};

export default texts;