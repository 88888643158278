var reroute = {
    /**
     * 
     * @param {UserPerrmisionArray} perrmissions 
     * 
     */
    redirectUser: function (perrmissions) {
        var permission = perrmissions[0]
    
        var firstRoute = this.resolveRoutes(permission)
        return firstRoute
    },
    resolveRoutes: function (permission) {
      switch (permission) {
        case '19feae76-e1df-4dc5-ba52-4a2ffbaba896':
          return '/admin/'
        case '19feae76-e1df-4dc5-ba52-4a2ffbaba896':
          return '/admin/orders'
        case '2334b4e0-ce21-41b1-ad22-0195bc13bea4':
          return '/admin/claims'
        case '257bc8cb-678f-4665-b239-9728735104ee':
          return '/admin/customers'
        case '88bea0cf-71b9-4d09-a334-755d88f4192c':
          return '/admin/vendors'
        case 'null':
          return '/admin/subadmins'
        case '0ac1f663-3ca6-47cf-b01c-6942b868067d':
          return '/admin/articles'
        case '23c3b8c6-2d70-4494-9ae7-b88ece90c971':
          return '/admin/categories'
        case '23c3b8c6-2d70-4494-9ae7-b88ece90c971':
          return '/admin/subcategories'
        case 'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c':
          return '/admin/commissions'
        case '3d681ddc-160c-4e73-b3e1-0ef07840563a':
          return '/admin/warehouse'
      }
    }
  }

export default reroute
