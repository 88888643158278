var texts = {
  en: {
    msgButton: 'Ok',
    msgTitle: 'Session ended',
    msgDescription: 'Your session has ended due to inactivity.'
  },
  sp: {
    msgButton: 'Aceptar',
    msgTitle: 'Sesión finalizada',
    msgDescription: 'Tu sesión ha finalizado por inactividad.'
  }
};

export default texts;