var texts = {
  en: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    forgotPassword: "Can't log in?",
    buttonLogin: 'Log in',
  },
  sp: {
    login: 'Inicio de sesión',
    title: '¡Bienvenido!',
    email: 'Correo electrónico',
    password: 'Contraseña',
    forgotPassword: '¿No puede iniciar sesión?',
    buttonLogin: 'Iniciar sesión',
  }
};

export default texts;