var texts = {
  en: {
    globalDialog: {
      textButtonConfirm: 'Confirm',
      textButtonCancel: 'Cancel'
    }
  },
  sp: {
    globalDialog: {
      textButtonConfirm: 'Confirmar',
      textButtonCancel: 'Cancelar'
    }
  }
};

export default texts;