const texts = {
    en: {
      warehouses: {
        textTitle: 'Warehouses',
        textButtonAdd: 'Add warehouse',
        textButtonView: 'View detail',
        totalwarehouse:'Warehouses total',
        dialogAdd: {
          textTitle: 'Add warehouse',
          warehouseName: 'Warehouse name',
          wahrehouseAddress: 'Address',
          wahrehouseAddressOptional: 'Optional específica',
          warehouseCountry: 'Country',
          warehouseState: 'State',
          warehouseCity: 'City',
          warehousePostalCode: 'Postal code',
          warehousePhoneNumber: 'Warehouse phone number',
          warehouseEmail: 'Email warehouse',
          warehousePrice: 'Price',
          warehouseCustomsPrice: 'Customs Price',
          reponsableWarehouse: 'Manager for the warehouse',
          warehouseLatitude: 'Latitude',
          warehouseLongitude: 'Longitude',
          warehouseComments: 'Comments',
          warehouseShippingLimitTime: 'Shipping limit time',
          buttonClose: 'Close',
          buttonAdd: 'Add warehouse',
          schedules: {
            textMonday: 'Monday',
            textTuesday: 'Tuesday',
            textWednesday: 'Wednesday',
            textThursday: 'Thursday',
            textFriday: 'Friday',
            textSaturday: 'Saturday',
            textSunday: 'Sunday',
            textFrom: 'From',
            textTo: 'To',
          }
        }
      },
      warehousedetail: {
        itemsTab: [
          'General information','Documents',
        ],
        dialogAddDocument: {
          textTitle: 'Add document',
          textDocumentName: 'Document name',
          textDocumentLabelFile: 'Click to add document',
          textButtonClose: 'Close',
          textButtonAdd: 'Save'
        },
        tableAttachments: {
          headers: ['Name', 'Document', 'Actions'],
          headersCard: [
            { text: "Name", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
            { text: "Document", value: 'sFileKey', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
            { text: "Actions", value: 'actions', align: 'center',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
        ],
        },
        buttonDelete: 'Delete',
        dialogDelete: {
          textTitle: 'Delete warehouse',
          textDescription: 'Are you sure to delete the warehouse? By performing this action it cannot be reversed',
          textSubDescription: 'Note: Items attached to the warehouse will go inactive, and completed orders will continue on their current intended course.',
          textConfirmation: 'I agree to delete the warehouses and everything related to it.'
        },
        dialogAdd: {
          textTitle: 'Add warehouse',
          warehouseName: 'Warehouse name',
          wahrehouseAddress: 'Address',
          wahrehouseAddressOptional: 'Optional específica',
          warehouseCountry: 'Country',
          warehouseState: 'State',
          warehouseCity: 'City',
          warehousePostalCode: 'Postal code',
          warehousePhoneNumber: 'Warehouse phone number',
          warehouseEmail: 'Email warehouse',
          warehousePrice: 'Price',
          warehouseCustomsPrice: 'Customs Price',
          reponsableWarehouse: 'Manager for the warehouse',
          warehouseLatitude: 'Latitude',
          warehouseLongitude: 'Longitude',
          warehouseComments: 'Comments',
          warehouseShippingLimitTime: 'Shipping limit time',
          buttonClose: 'Close',
          buttonAdd: 'Add warehouse',
          schedules: {
            textMonday: 'Monday',
            textTuesday: 'Tuesday',
            textWednesday: 'Wednesday',
            textThursday: 'Thursday',
            textFriday: 'Friday',
            textSaturday: 'Saturday',
            textSunday: 'Sunday',
            textFrom: 'From',
            textTo: 'To',
          }
        },
        deleteTextDocument: 'warehouse document',
        textTitleDelete: 'warehouse',
        delete: 'Delete',
        wiewDetail: 'View detail',
        emptyText: 'There are no registered warehouses yet.',
        attachments: {
          dragFile: 'Drag a file',
          selectFile: 'Select a file',
          or: 'or',
          textNoDocuments: 'Sin documentos',
          buttonClose: 'Cerrar',
          buttonAddWarehouse: 'Crear almacén',
          buttonAddDocument: 'Add document',
        },
        photographs: {
          textName: 'Name',
          textActions: 'Actions',
          textNoDocuments: 'Without documents',
          buttonClose: 'Close',
          buttonAddWarehouse: 'Add warehouse',
        },
        textTitle: 'Warehouse',
        buttonAddWarehouse: 'Add warehouse',
        addWarehouse: {
          generalInformation: 'General information',
          attachments: 'Attachments',
          images: 'Photographs',
          buttonReturn: 'Return',
          textTitle: 'New warehouse',
          warehouseName: {
            label: 'Warehouse name',
            placeholder: 'Warehouse name',
          },
          wahrehouseAddress: {
            label: 'Address',
            placeholder: 'Address',
          },
          wahrehouseAddressOptional: {
            label: 'Optional específica',
            placeholder: 'Optional específica',
          },
          warehouseCountry: {
            label: 'Country',
            placeholder: 'Country',
          },
          warehouseState: {
            label: 'State',
            placeholder: 'State',
          },
          warehouseCity: {
            label: 'City',
            placeholder: 'City',
          },
          warehousePostalCode: {
            label: 'Postal code',
            placeholder: 'Postal code',
          },
          warehousePhoneNumber: {
            label: 'Warehouse phone number',
            placeholder: 'Warehouse phone number',
          },
          warehouseEmail: {
            label: 'Email warehouse',
            placeholder: 'Email warehouse',
          },
          warehousePrice: {
            label: 'Price',
            placeholder: 'Price',
          },
          warehouseCustomsPrice: {
            label: 'Customs Price',
            placeholder: 'Customs Price',
          },
          reponsableWarehouse: {
            label: 'Responsible for the warehouse',
            placeholder: 'Responsible for the warehouse',
          },
          warehouseLatitude: {
            label: 'Latitude',
            placeholder: 'Latitude',
          },
          warehouseLongitude: {
            label: 'Longitude',
            placeholder: 'Longitude',
          },
          warehouseComments: {
            label: 'Comments',
            placeholder: 'Comments',
          },
          buttonClose: 'Return',
          buttonAddWarehouse: 'Add warehouse',
        },
        buttonSaveChangeWarehouse: 'Save',
        buttonModifyWarehouse:'Modify',
        deleteWarehouse:{
          title:'Delete warehouse',
          description:'Are you sure to delete the warehouse? By performing this action it cannot be reversed',
          note:'Note: Items attached to the warehouse will go inactive, and completed orders will continue their current intended course.',
          chkLabel:'I agree to remove the warehouse and everything related to him.',
          cancel:'Cancel',
          delete:'Delete'
      },
        deleteDocument:{
          title:'Delete document',
          description:'Are you sure to delete the document? By performing this action it cannot be reversed',
          note:'',
          chkLabel:'',
          cancel:'Cancel',
          delete:'Delete'
      }

      }
    },
    sp: {
      warehouses: {
        textTitle: 'Almacenes',
        textButtonAdd: 'Añadir almacén',
        textButtonView: 'Ver detalle',
        totalwarehouse:' Almacenes totales',
        dialogAdd: {
          textTitle: 'Añadir almacén',
          warehouseName: 'Nombre del almacén',
          wahrehouseAddress: 'Dirección',
          wahrehouseAddressOptional: 'Dirección específica',
          warehouseCountry: 'País',
          warehouseState: 'Estado',
          warehouseCity: 'Ciudad',
          warehousePostalCode: 'Código postal',
          warehousePhoneNumber: 'Teléfono del almacén',
          warehouseEmail: 'Correo electrónico del almacén',
          warehousePrice: 'Costo del almacen',
          warehouseCustomsPrice: 'Costo de aduana',
          reponsableWarehouse: 'Responsable del almacén',
          warehouseLatitude: 'Latitud',
          warehouseLongitude: 'Longitud',
          warehouseComments: 'Comentarios',
          warehouseShippingLimitTime: 'Tiempo límite de envío',
          buttonClose: 'Cerrar',
          buttonAdd: 'Añadir almacén',
          schedules: {
            textMonday: 'Lunes',
            textTuesday: 'Martes',
            textWednesday: 'Miercoles',
            textThursday: 'Jueves',
            textFriday: 'Viernes',
            textSaturday: 'Sabado',
            textSunday: 'Domingo',
            textFrom: 'Desde',
            textTo: 'Hasta',
          }
        }
      },
      warehousedetail: {
        itemsTab: [
          'Información general','Documentos',
        ],
        dialogAddDocument: {
          textTitle: 'Añadir documento',
          textDocumentName: 'Nombre del documento',
          textDocumentLabelFile: 'Da click para agregar el documento',
          textButtonClose: 'Cerrar',
          textButtonAdd: 'Guardar'
        },
        tableAttachments: {
          headers: ['Nombre', 'Documento', 'Acciones'],
          headersCard: [
            { text: "Nombre", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
            { text: "Documento", value: 'sFileKey', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
            { text: "Acciones", value: 'actions', align: 'center', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },

        ],
        },
        buttonDelete: 'Eliminar',
        dialogDelete: {
          textTitle: 'Eliminar almacén',
          textDescription: '¿Estás seguro de eliminar el almacén? Al realizar esta acción no podrá revertirse',
          textSubDescription: 'Nota: Los artículos adjuntos al almacén pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
          textConfirmation: 'Estoy de acuerdo con eliminar el alamcén y todo lo relacionado a él.'
        },
        dialogAdd: {
          textTitle: 'Añadir almacén',
          warehouseName: 'Nombre del almacén',
          wahrehouseAddress: 'Dirección',
          wahrehouseAddressOptional: 'Dirección específica',
          warehouseCountry: 'País',
          warehouseState: 'Estado',
          warehouseCity: 'Ciudad',
          warehousePostalCode: 'Código postal',
          warehousePhoneNumber: 'Teléfono del almacén',
          warehouseEmail: 'Correo electrónico del almacén',
          warehousePrice: 'Costo del almacen',
          warehouseCustomsPrice: 'Costo de aduana',
          reponsableWarehouse: 'Responsable del almacén',
          warehouseLatitude: 'Latitud',
          warehouseLongitude: 'Longitud',
          warehouseComments: 'Comentarios',
          warehouseShippingLimitTime: 'Tiempo límite de envío',
          buttonClose: 'Cerrar',
          buttonAdd: 'Añadir almacén',
          schedules: {
            textMonday: 'Lunes',
            textTuesday: 'Martes',
            textWednesday: 'Miercoles',
            textThursday: 'Jueves',
            textFriday: 'Viernes',
            textSaturday: 'Sabado',
            textSunday: 'Domingo',
            textFrom: 'Desde',
            textTo: 'Hasta',
          }
        },
        deleteTextDocument: 'documento de almacén',
        textTitleDelete: 'almacén',
        delete: 'Eliminar',
        wiewDetail: 'Ver detalle',
        emptyText: 'Aún no hay almacenes registrados.',
        attachments: {
          buttonAddDocument: 'Añadir documento',
          textName: 'Nombre',
          textActions: 'Acciones',
          textNoDocuments: 'Sin documentos',
          buttonClose: 'Cerrar',
          buttonAddWarehouse: 'Crear almacén',
        },
        photographs: {
          dragFile: 'Arrastra un archivo',
          selectFile: 'Selecciona un archivo',
          or: 'ó',
          textNoDocuments: 'Sin documentos',
          buttonClose: 'Cerrar',
          buttonAddWarehouse: 'Crear almacén',
        },
        textTitle: 'Almacenes',
        buttonAddWarehouse: 'Añadir almacén',
        addWarehouse: {
          generalInformation: 'Información general',
          attachments: 'Documentos',
          images: 'Imagenes',
          buttonReturn: 'Regresar',
          textTitle: 'Nuevo almacén',
          warehouseName: {
            label: 'Nombre del almacén',
            placeholder: 'Nombre del almacén',
          },
          wahrehouseAddress: {
            label: 'Dirección',
            placeholder: 'Dirección',
          },
          wahrehouseAddressOptional: {
            label: 'Dirección específica',
            placeholder: 'Dirección específica',
          },
          warehouseCountry: {
            label: 'País',
            placeholder: 'País',
          },
          warehouseState: {
            label: 'Estado',
            placeholder: 'Estado',
          },
          warehouseCity: {
            label: 'Ciudad',
            placeholder: 'Ciudad',
          },
          warehousePostalCode: {
            label: 'Código postal',
            placeholder: 'Código postal',
          },
          warehousePhoneNumber: {
            label: 'Teléfono del almacén',
            placeholder: 'Teléfono del almacén',
          },
          warehouseEmail: {
            label: 'Correo del almacén',
            placeholder: 'Correo del almacén',
          },
          warehousePrice: {
            label: 'Costo del almacén',
            placeholder: 'Costo del almacén',
          },
          warehouseCustomsPrice: {
            label: 'Costo de frontera',
            placeholder: 'Costo de frontera',
          },
          reponsableWarehouse: {
            label: 'Responsable del almacén',
            placeholder: 'Responsable del almacén',
          },
          warehouseLatitude: {
            label: 'Latitud',
            placeholder: 'Latitud',
          },
          warehouseLongitude: {
            label: 'Longitud',
            placeholder: 'Longitud',
          },
          warehouseComments: {
            label: 'Comentarios',
            placeholder: 'Comentarios',
          },
          buttonClose: 'Regresar',
          buttonAddWarehouse: 'Crear almacén',
        },
        buttonSaveChangeWarehouse: 'Guardar',
        buttonModifyWarehouse:'Modificar',
        deleteWarehouse:{
          title:'Eliminar almacén',
          description:'¿Estás seguro de eliminar el almacén? Al realizar esta acción no podrá revertirse',
          note:'Nota: Los artículos adjuntos al almacén pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
          chkLabel:'Estoy de acuerdo con eliminar el almacén y todo lo relacionado a él.',
          cancel:'Cancelar',
          delete:'Eliminar'
      },
        deleteDocument:{
          title:'Eliminar documento',
          description:'¿Estás seguro de eliminar el documento? Al realizar esta acción no podrá revertirse',
          note:'',
          chkLabel:'',
          cancel:'Cancelar',
          delete:'Eliminar'
      }
      }
    }
  }
  
  export default texts;
