var texts = {
  en: {
    filters: {
      textSearch: 'Search',
      startDate: 'Start date',
      endDate: 'End date',
      status: 'Status',
      // paymentStatus: 'Payment status',
      textLabelStatus: 'Status',
      textLabelStatusPaid: 'Payment status',
      paymentStatusPaid: 'Paid',
      paymentStatusToPaid: 'Unpaid',
      textLabelStatusUser: 'User status',
      userStatusActive: 'Active',
      userStatusInactive: 'Inactive',
      orderStatus: [
        {
          value: "247b4a0b-8796-4b59-83f6-84043b1a5472",
          name: "Order received",
        },
        {
          value: "067590d6-f90d-4308-8c59-72840bdfa085",
          name: "in preparation",
        },
        {
          value: "939ec62b-5060-4079-aadf-282e6abba399",
          name: "In transit",
        },
        {
          value: "f6970943-c9d3-4935-97ad-665968544c2e",
          name: "In the process of border crossing",
        },
        {
          value: "227d8762-e17a-4d85-b372-0b78ea11ee34",
          name: "Successful crossover",
        },
        {
          value: "b7b6ccfa-23cf-44fc-871d-be29e7777a83",
          name: "Warehouse border",
        },
        {
          value: "ea1a9952-a6ac-4931-8a95-9ad62fcdb33b",
          name: "Accepted at border warehouse",
        },
        {
          value: "1cb2626a-1cb1-4bb7-ac55-63a5b754c725",
          name: "Transferred",
        },
        {
          value: "130afe3b-178b-4e89-bf74-7b42ad99b948",
          name: "On delivery route",
        },
        {
          value: "e79ef080-50d8-4aa6-a198-58a491c8a772",
          name: "Delivered",
        },
      ],
      claimsStatus: [
        {
          value: "ca16f9be-c88c-431e-a053-625f205a671d",
          name: "Started"
        },
        {
          value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
          name: "Analyzing"
        },
        {
          value: "69b41776-e327-4d72-8132-1428d0c57631",
          name: "Scheduled inspection"
        },
        {
          value: "db2289ae-6222-4785-9587-0276e0c21657",
          name: "Resolved"
        },
        {
          value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
          name: "Cancelled"
        }
      ],
      articleStatus: {
        clasification:'clasification',
        aRange: [
          {iRange : 5},
          {iRange : 4},
          {iRange : 3},
          {iRange : 2},
          {iRange : 1},
        ],
        alphabetically:'alphabetically',
        AZ:'A-Z',
        ZA:'Z-A',
        price:'Price',
        fromLowestToHighest:'From lowest to highestr',
        fromHighestToLowest:'From highest to lowest'
      }
    }
  },
  sp: {
    filters: {
      textSearch: 'Buscar',
      startDate: 'Fecha inicio',
      endDate: 'Fecha final',
      status: 'Estado',
      // paymentStatus: 'Estado de pago',
      textLabelStatus: 'Estado',
      textLabelStatusPaid: 'Estado de pago',
      paymentStatusPaid: 'Pagado',
      paymentStatusToPaid: 'No pagado',
      textLabelStatusUser: 'Estado del usuario',
      userStatusActive: 'Activo',
      userStatusInactive: 'Inactivo',
      orderStatus: [
        {
          value: "247b4a0b-8796-4b59-83f6-84043b1a5472",
          name: "Pedido Recibido",
        },
        {
          value: "067590d6-f90d-4308-8c59-72840bdfa085",
          name: "En preparación",
        },
        {
          value: "939ec62b-5060-4079-aadf-282e6abba399",
          name: "En tránsito",
        },
        {
          value: "f6970943-c9d3-4935-97ad-665968544c2e",
          name: "En proceso de cruce fronterizo",
        },
        {
          value: "227d8762-e17a-4d85-b372-0b78ea11ee34",
          name: "Cruce exitoso",
        },
        {
          value: "b7b6ccfa-23cf-44fc-871d-be29e7777a83",
          name: "Almacén frontera",
        },
        {
          value: "ea1a9952-a6ac-4931-8a95-9ad62fcdb33b",
          name: "Aceptado en almacén frontera",
        },
        {
          value: "1cb2626a-1cb1-4bb7-ac55-63a5b754c725",
          name: "Transferido",
        },
        {
          value: "130afe3b-178b-4e89-bf74-7b42ad99b948",
          name: "En ruta de entrega",
        },
        {
          value: "e79ef080-50d8-4aa6-a198-58a491c8a772",
          name: "Entregado",
        },
      ],
      claimsStatus: [
        {
          value: "ca16f9be-c88c-431e-a053-625f205a671d",
          name: "Iniciada"
        },
        {
          value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
          name: "Analizando"
        },
        {
          value: "69b41776-e327-4d72-8132-1428d0c57631",
          name: "Inspección programada"
        },
        {
          value: "db2289ae-6222-4785-9587-0276e0c21657",
          name: "Resuelta"
        },
        {
          value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
          name: "Cancelada"
        }
      ],
      articleStatus: {
        clasification:'Calificación',
        aRange: [
          {iRange : 5},
          {iRange : 4},
          {iRange : 3},
          {iRange : 2},
          {iRange : 1},
        ],
        alphabetically:'Alfabéticamente',
        AZ:'A-Z',
        ZA:'Z-A',
        price:'Precio',
        fromLowestToHighest:'De menor a mayor',
        fromHighestToLowest:'De mayor a menor'
      }
    }
  }
};
export default texts;