var texts = {
    en: {
        table: {
            headers: [
                { text: "Name", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14 colName text-ellipsis' },
                { text: "Email", value: 'sEmail', cellClass: 'mon f-s14 color000000', class: 'f-s14 colEmail' },
                { text: "Phone", value: 'sPhoneNumber', cellClass: 'mon f-s14 color000000', class: 'f-s14 colPhoneNumber' },
                { text: "Creation date", value: 'tCreatedAtView', cellClass: 'mon f-s14 color000000', class: 'f-s14 colCreatedAt' },
                { text: "Status", value: 'bBlockStatus', class: 'f-s14 colActive' },
                { text: "Verified", value: 'bVerified', cellClass: 'mon f-s14 color000000', class: 'f-s14 colVerified' },
                { text: "Actions", value: 'actions', align: 'center',cellClass:'nolinebreak', class: 'f-s14 colActions' },
            ]
            ,
            actions: {
                labels: {
                    textEdit: 'Edit',
                    textActivate: 'Enabled',
                    textDeactivate: 'Disabled',
                    textRecoveryPassword: 'Reset password',
                    textDelete: 'Delete',
                    textDetails: 'Detail'
                }
            },
            status: {
                textActivate: 'Enabled',
                textDeactivate: 'Disabled',
                actives: 'Unlocked',
                inactives: 'Blocked',
                Unverified: 'Unverified',
                verified: 'Verified'
            },
        },
        pagination: {
            paginationRowPerPage: 'Rows per page',
            of: 'of'
        },
        dialogDelete: {
            textTitle: 'Customers',
        },
        dialogEdit: {
            title: 'Edit customer',
            description: 'Edit the information to display for the customer in the list.',
            placeholders: {
                latitude: 'Capture latitude',
                longitude: 'Capture longitude',
                blueBook: 'Capture the id of the blue book',
                description: 'Capture the description',
                email: 'Capture email',
                address: 'Capture the address',
                city: 'Capture the city',
                zipCode: 'Capture the zip code',
                name: 'Capture the name',
                license: 'Capture the PACA license number',
                phoneNumber: 'Capture the phone number',
                state: 'Select the state',
                taxPayerId: 'Capture the tax payer id',
                country: 'Select the country'
            },
            buttons: {
                edit: 'Edit',
                cancel: 'Cancel'
            },
            labels: {
                latitude: 'Latitude',
                longitude: 'Longitude',
                blueBook: 'Blue book Id',
                description: 'Description',
                email: 'Email',
                address: 'Address',
                city: 'City',
                zipCode: 'Zip code',
                name: 'Name',
                license: 'PACA license',
                phoneNumber: 'Phone number',
                state: 'State',
                taxPayerId: 'Tax payer id',
                country: 'Country'
            }
        },
        tooltip: {
            search: 'Search'
        },
        tabsItems: {
            actives: 'Actives',
            inactives: 'Inactives',
        },
        vendorDetails: {
            itemsTab: [
                'Profile', 'General information', 'Articles', 'Users', 'Warehouses', 'Documents',
            ],
            profileDetail: {
                clasifications: 'Clasifications',
                status: 'Status',
                active: 'Active',
                inActive: 'inActive',
                description: 'Description',
                averageResponseTime: "Average response time",
                ordersCompleted: 'Orders completed',
                registrationDatePlatform: 'Registration date to the platform',
                country: 'Country',
                state: 'State',
                city: 'City',
                attentionSchedule: 'Business hours schedule',
                monday: 'Monday',
                tuesday: 'tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday',
                delete: 'Delete',
                toReturn: 'Return',
                changePassword: 'Change password',
                deleteTexts: {
                    title: 'Delete provider',
                    description: 'Are you sure to delete the provider? By performing this action it cannot be reversed',
                    note: 'Note: Items attached to the vendor will go inactive, and completed orders will continue on their current intended course.',
                    chkLabel: 'I agree to remove the provider and everything related to him.',
                    cancel: 'Cancel',
                    delete: 'Delete'
                },
                verifiedVendor: 'Vendor verified',
                vendorNotVerified: 'Vendor restricted',
                toRestrict: 'Restrict',
                verified: 'Verified',
                toRestrictTexts: {
                    title: 'Provider verification',
                    description: "You are about to restrict a provider's activity. Are you sure to take that action?",
                    descriptionVerified: "You are about to verified a provider's activity. Are you sure to take that action?",
                    note: 'Note: The provider will not be able to carry out transactions within the platform until it is verified again.',
                    noteVerified: 'The provider will be able to carry out transactions within the platform.',
                    chkLabel: 'I agree to restrict the user and everything related to him.',
                    chkLabelVerified: 'I agree to verified the user and everything related to him.',
                    cancel: 'Cancel',
                    confirm: 'Confirm'
                },
                activeProvider: 'Active supplier',
                inactiveProvider: 'Inactive supplier',
                vendorBlocked: 'Vendor blocked',
                vendorUnlocked: 'Vendor unlocked',
                toBlock: 'Block',
                toUnlock: 'Unlock',
                changeStatusTexts: {
                    title: 'Confirmation of change',
                    description: 'You are about to change the status of the vendor to blocked. Are you sure to take that action? ',
                    descriptionUnlock: 'You are about to enable the provider. Are you sure to take that action? ',
                    note: 'Note: Items attached to the supplier will go inactive, and completed orders will continue their current expected course.',
                    noteUnlock: 'Note: Items attached to the supplier will go active, and completed orders will continue their current expected course.',
                    chkLabel: 'I agree to block the user and everything related to him.',
                    chkLabelUnlock: 'I agree to Unlock the user and everything related to him.',
                    cancel: 'Cancel',
                    confirm: 'Confirm'
                },
                ratings: 'Ratings'


            },
            generalInformation: {
                userDetail: {
                    userData: 'User data',
                    name: 'Name',
                    lastName: 'Last name',
                    mail: 'Mail',
                    position: 'Position',
                    phone: 'Phone',
                },
                enterpriseDetail: {
                    companyData: 'Company data',
                    companyName: 'Company name',
                    legalAddressCompany: 'Legal address of the company',
                    legalAddressOptionalCompany: 'Optional legal company address',
                    address: 'Address',
                    postalCode: 'Postal code',
                    companyEmail: 'Company mail',
                    companyPhone: 'Company phone',
                    PACALicenseNumber: 'PACA license number',
                    blueBookNumber: 'Blue book number',
                    RFC: 'Federal taxpayer registration',
                    enterpriseDescription: 'Company description',
                }
            },
            articleDetail: {
                category: 'Category',
                subCategory: 'Subcategory',
                search: 'Search'

            },
            usersDetail: {
                headers: [
                    { text: "Name", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    // {text:"Last name", value:'sLastname',cellClass:'mon f-s14 color000000',class:'f-s14 text-ellipsis'},
                    { text: "Email", value: 'sEmail', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Position", value: 'sPositionName', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Phone", value: 'sPhoneNumber', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Status", value: 'bPlatformAccess', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-center' },
                    { text: "Actions", value: 'actions', align: 'center', class: 'f-s14 text-center ' },

                ],
                status: {
                    active: 'Active',
                    inactive: 'Inactive'
                },
                detailByUser: {
                    userDetail: 'User detail'
                },
                deleteTexts: {
                    title: 'Delete user',
                    description: 'Are you sure to delete the user? By performing this action it cannot be reversed ',
                    note: 'Note: Items attached to the user will go inactive, and completed orders will continue their current planned course.',
                    chkLabel: 'I agree to remove the user and everything related to him.',
                    cancel: 'Cancel',
                    delete: 'Delete'
                  },

            },
            warehousesDetail: {
                DetailsWarehouse: {
                    description: 'Description',
                    warehouseDetail: 'Warehouse detail',
                    warehouseName: 'Warehouse name',
                    address: 'Address',
                    country: 'Country',
                    state: 'State',
                    city: 'City',
                    postalCode: 'Postal code',
                    warehousePhone: 'Warehouse phone',
                    warehouseManager: 'Warehouse manager',
                    warehouseContactEmail: 'Warehouse contact email',
                    deleteText: 'Delete',
                    retrunText: 'Return',
                    watchText: 'Watch',
                    noFiles:'no files',
                    itemHeaderWareHouse: [
                        { text: 'Name', value: 'sName', cellClass:'f-s18',class:'f-s14' },
                        { text: 'File', value: 'sFileKey' }]
                },
                delete: {
                    title: 'Delete warehouse',
                    description: 'Are you sure to delete the warehouse? By performing this action it cannot be reversed',
                    note: 'Note: Items attached to the warehouse will go inactive, and completed orders will continue their current intended course.',
                    chkLabel: 'I agree to remove the warehouse and everything related to him.',
                    cancel: 'Cancel',
                    delete: 'Delete'
                }
            },
            documentsDetails: {
                headers: [
                    { text: "Name", value: 'sVendorFileTypeName', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
                    { text: "Company document", value: 'sFileKey', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
                    { text: "Actions", value: 'actions', align: 'center',  cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis'},
                ],
                delete: {
                    title: 'Delete document',
                    description: 'Are you sure to delete the document? By performing this action it cannot be reversed ',
                    note: '',
                    chkLabel: '',
                    cancel: 'Cancel',
                    delete: 'Delete'
                }
            }

        },
        searchbar:{
            search:'Provider name ...'
        },
        informationNotYetRegistered:'Information not yet registered'

    },
    sp: {
        table: {
            headers: [
                { text: "Nombre", value: 'sName', cellClass: 'mon f-s14 color000000 ', class: 'f-s14 colName' },
                { text: "Correo", value: 'sEmail', cellClass: 'mon f-s14 color000000 text-ellipsis', class: 'f-s14 colEmail' },
                { text: "Teléfono", value: 'sPhoneNumber', cellClass: 'mon f-s14 color000000 ', class: 'f-s14 colPhoneNumber' },
                { text: "Fecha de creación", value: 'tCreatedAtView', cellClass: 'mon f-s14 color000000 ', class: 'f-s14 colCreatedAt' },
                { text: "Estado", value: 'bBlockStatus', class: 'f-s14 colActive' },
                { text: "Verificado", value: 'bVerified', align: 'center', cellClass: 'mon f-s14 color000000 ', class: 'f-s14 colVerified' },
                { text: "Acciones", value: 'actions', align: 'center',cellClass:'nolinebreak', class: 'f-s14 colActions' },
            ],
            actions: {
                labels: {
                    textEdit: 'Editar',
                    textActivate: 'Activar',
                    textDeactivate: 'Desactivar',
                    textRecoveryPassword: 'Recuperar contraseña',
                    textDelete: 'Eliminar',
                    textDetails: 'Detalle'
                }
            },
            status: {
                textActivate: 'Activado',
                textDeactivate: 'Desactivado',
                actives: 'Habilitado',
                inactives: 'Bloqueado',
                Unverified: 'No verificado',
                verified: 'Verificado'
            },
        },
        pagination: {
            paginationRowPerPage: 'Filas por página',
            of: 'de'
        },
        dialogDelete: {
            textTitle: 'Compradores',
        },
        dialogEdit: {
            title: 'Editar comprador',
            description: 'Edite la información a mostrar del subadministrador en el listado.',
            placeholders: {
                latitude: 'Capture la latitud',
                longitude: 'Capture la longitud',
                blueBook: 'Capture el id del blue book',
                description: 'Capture la descripción',
                email: 'Capture el correo eletrónico',
                address: 'Capture la dirección',
                city: 'Capture la ciudad',
                zipCode: 'Capture el código postal',
                name: 'Capture el nombre',
                license: 'Capture el número de lincencia de PACA',
                phoneNumber: 'Capture el número telefónico',
                state: 'Seleccione el estado',
                taxPayerId: 'Capture el tax payer id',
                country: 'Seleccione el país'
            },
            buttons: {
                edit: 'Editar',
                cancel: 'Cancelar'
            },
            labels: {
                latitude: 'Latitud',
                longitude: 'Longitud',
                blueBook: 'Id blue book',
                description: 'Descripción',
                email: 'Correo electrónico',
                address: 'Dirección',
                city: 'Ciudad',
                zipCode: 'Código postal',
                name: 'Nombre',
                license: 'Lincencia PACA',
                phoneNumber: 'Número telefónico',
                state: 'Estado',
                taxPayerId: 'Id tax payer',
                country: 'País'
            }
        },
        tooltip: {
            search: 'Buscar'
        },
        tabsItems: {
            actives: 'Activos',
            inactives: 'Inactivos',
        },
        vendorDetails: {
            itemsTab: [
                'Perfil', 'Información general', 'Artículos', 'Usuarios', 'Almacenes', 'Documentos',
            ],

            profileDetail: {
                clasifications: 'clasificaciones',
                status: 'Estado',
                active: 'Activo',
                inActive: 'Inactivo',
                description: 'Descripción',
                averageResponseTime: "Tiempo de respuesta promedio",
                ordersCompleted: 'Pedidos concretados',
                registrationDatePlatform: 'Fecha de registro a la plataforma',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                attentionSchedule: 'Horarios de atención',
                monday: 'Lunes',
                tuesday: 'Martes',
                wednesday: 'Miércoles',
                thursday: 'Jueves',
                friday: 'Viernes',
                saturday: 'Sabado',
                sunday: 'Domingo',
                delete: 'Eliminar',
                toReturn: 'Regresar',
                changePassword: 'Cambiar contraseña',
                deleteTexts: {
                    title: 'Eliminar proveedor',
                    description: '¿Estás seguro de eliminar el proveedor? Al realizar esta acción no podrá revertirse',
                    note: 'Nota: Los artículos adjuntos al proveedor pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
                    chkLabel: 'Estoy de acuerdo con eliminar al proveedor y todo lo relacionado a él.',
                    cancel: 'Cancelar',
                    delete: 'Eliminar'
                },
                verifiedVendor: 'Proveedor verificado',
                vendorNotVerified: 'Proveedor restringido',
                toRestrict: 'Restringir',
                verified: 'Verificar',
                toRestrictTexts: {
                    title: 'Verificación de proveedor',
                    description: 'Estás a punto de restringir la actividad de un proveedor. ¿Estás seguro de realizar esa acción?',
                    descriptionVerified: 'Estás a punto de verificar la actividad de un proveedor. ¿Estás seguro de realizar esa acción?',
                    note: 'Nota: El proveedor no podrá realizar transacciones dentro de la plataforma hasta que sea verificado nuevamente.',
                    noteVerified: 'Nota: El proveedor podrá realizar transacciones dentro de la plataforma.',
                    chkLabel: 'Estoy de acuerdo con restringir el usuario y todo lo relacionado a él.',
                    chkLabelVerified: 'Estoy de acuerdo con verificar el usuario y todo lo relacionado a él.',
                    cancel: 'Cancelar',
                    confirm: 'Confirmar'
                },
                vendorBlocked: 'Proveedor bloqueado',
                vendorUnlocked: 'Proveedor habilitado',
                toBlock: 'Bloquear',
                toUnlock: 'Habilitar',
                changeStatusTexts: {
                    title: 'Confirmación de cambio',
                    description: 'Estás a punto de cambiar el estado del proveedor a bloqueado. ¿Estás seguro de realizar esa acción?',
                    descriptionUnlock: 'Estás a punto de habilitar el proveedor. ¿Estás seguro de realizar esa acción?',
                    note: 'Nota: Los artículos adjuntos al proveedor pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
                    noteUnlock: 'Nota: Los artículos adjuntos al proveedor pasarán a estado activo, y los pedidos concretados continuarán su curso actual previsto.',
                    chkLabel: 'Estoy de acuerdo con bloquear el usuario y todo lo relacionado a él.',
                    chkLabelUnlock: 'Estoy de acuerdo con habilitar el usuario y todo lo relacionado a él.',
                    cancel: 'Cancelar',
                    confirm: 'Confirmar'
                },
                ratings: 'calificaciones'

            },
            generalInformation: {
                userDetail: {
                    userData: 'Datos del usuario',
                    name: 'Nombre',
                    lastName: 'Apellidos',
                    mail: 'Correo',
                    position: 'Posición',
                    phone: 'Teléfono',
                },
                enterpriseDetail: {
                    companyData: 'Datos de la Empresa',
                    companyName: 'Nombre de la empresa',
                    legalAddressCompany: 'Dirección legal de la empresa',
                    legalAddressOptionalCompany: 'Dirección opcional legal de la empresa',
                    postalCode: 'Código Postal',
                    companyEmail: 'Correo de la empresa',
                    companyPhone: 'Teléfono de la empresa',
                    PACALicenseNumber: 'PACA license number',
                    blueBookNumber: 'Blue book number',
                    RFC: 'RFC',
                    enterpriseDescription: 'Descripción de la empresa',
                }
            },
            articleDetail: {
                category: 'Categoría',
                subCategory: 'Subcateogría',
                search: 'Buscar'
                // dialogDetailArticle:{
                //     title:'Detalle del articulo'
                // }
            },
            usersDetail: {
                headers: [
                    { text: "Nombre", value: 'sName', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    // {text:"Apellido", value:'sLastname', cellClass:'mon f-s14 color000000',class:'f-s14 text-ellipsis'},
                    { text: "Correo electrónico", value: 'sEmail', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Posición", value: 'sPositionName', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Teléfono", value: 'sPhoneNumber', cellClass: 'mon f-s14 color000000', class: 'f-s14 ' },
                    { text: "Estado", value: 'bPlatformAccess', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-center ' },
                    { text: "Acciones", value: 'actions', align: 'center', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-center' },
                ],
                status: {
                    active: 'Activo',
                    inactive: 'Inactivo'
                },
                detailByUser: {
                    userDetail: 'Detalle del usuario'
                },
                deleteTexts: {
                    title: 'Eliminar usuario',
                    description: '¿Estás seguro de eliminar el usuario? Al realizar esta acción no podrá revertirse',
                    note: 'Nota: Los artículos adjuntos al usuario pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
                    chkLabel: 'Estoy de acuerdo con eliminar al usuario y todo lo relacionado a él.',
                    cancel: 'Cancelar',
                    delete: 'Eliminar'
                  },
            },
            warehousesDetail: {
                DetailsWarehouse: {
                    description: 'Descripción',
                    warehouseDetail: 'Detalle de almacén',
                    warehouseName: 'Nombre del almacén',
                    address: 'Dirección',
                    country: 'País',
                    state: 'Estado',
                    city: 'Ciudad',
                    postalCode: 'Código postal',
                    warehousePhone: 'Teléfono del almacén',
                    warehouseManager: 'Responsable del almacén',
                    warehouseContactEmail: 'Correo de contacto del almacén',
                    deleteText: 'Eliminar',
                    retrunText: 'Regresar',
                    watchText: 'Ver',
                    noFiles:'No hay archivos',
                    itemHeaderWareHouse: [
                        { text: 'Nombre', value: 'sName',cellClass:'f-s12 mon-regular',class:'f-s14'  },
                        { text: 'Archivo', value: 'sFileKey', cellClass:'f-s12 mon-regular', class:'f-s14' }]
                },
                delete: {
                    title: 'Eliminar almacén',
                    description: '¿Estás seguro de eliminar el almacén? Al realizar esta acción no podrá revertirse',
                    note: 'Nota: Los artículos adjuntos al almacén pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.',
                    chkLabel: 'Estoy de acuerdo con eliminar el almacén y todo lo relacionado a él.',
                    cancel: 'Cancelar',
                    delete: 'Eliminar'
                }
            },
            documentsDetails: {
                headers: [
                    { text: "Nombre", value: 'sVendorFileTypeName', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
                    { text: "Documento de la empresa", value: 'sFileKey', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },
                    { text: "Acciones", value: 'actions', align: 'center', cellClass: 'mon f-s14 color000000', class: 'f-s14 text-ellipsis' },

                ],
                delete: {
                    title: 'Eliminar documento',
                    description: '¿Estás seguro de eliminar el documento? Al realizar esta acción no podrá revertirse',
                    note: '',
                    chkLabel: '',
                    cancel: 'Cancelar',
                    delete: 'Eliminar'
                }
            }

        },
        searchbar:{
            search:'Nombre de proveedor...'
        },
        informationNotYetRegistered:'Información no registrada aún'
    }
};

export default texts;