var texts = {
  en: {
    msgButtonSearchbar: 'Add',
    textTitleModal: 'New catalog',
    textDescriptionModal: 'Capture and add an image to show the new catalog in the listing.',
    placeholderCategoryNameEn: 'Category name in english',
    placeholderCategoryNameEs: 'Category name in spanish',
    placeholderInspectionEs: 'Inspection cost',
    paginationRowPerPage: 'Rows per page:',
    modalButtonAdd: 'Add',
    modalButtonCancel: 'Cancel',
    textDialogDelete: 'Delete category',
    modalButtonEdit: 'Save',
    // --- texts dialog edit --- //
    dialogEditTextTitle: 'Edit category',
    dialodEditTextDescription: 'Edit the information to display for the category in the list.',
    // --- texts pagination --- //
    of: 'of',
    labels: {
      categoryNameEn: 'Category name (English)',
      categoryNameSp: 'Category name (Spanish)',
      categoryType: 'Category type',
      colorType: 'Color type',
      dInspectionCost: 'Inspection cost'
    },
    tooltip: {
      addCategory: 'Add category',
      search: 'Search'
    },
    upload: {
      message: 'The category must have an image.',
      text: 'Current image'
    },    
    delete: {
      textDescription: 'Are you sure to delete this item? By performing this action, it cannot be reversed'
    },
    searchbar:{
      search:'Category name...',
      categoryType: 'Category type',
      addCategory: 'Add category',

    },
    informationNotYetRegistered:'Information not yet registered'

  },
  sp: {
    msgButtonSearchbar: 'Agregar',
    textTitleModal: 'Nueva categoría',
    textDescriptionModal: 'Capture y agregue una imagen para mostrar el nuevo catálago en el listado.',
    placeholderCategoryNameEn: 'Nombre de la categoría en inglés',
    placeholderCategoryNameEs: 'Nombre de la categoría en español',
    placeholderInspectionEs: 'Costo de inspección',
    paginationRowPerPage: 'Filas por página:',
    modalButtonAdd: 'Agregar',
    modalButtonCancel: 'Cancelar',
    textDialogDelete: 'Eliminar categoría',
    modalButtonEdit: 'Guardar',
    // --- texts dialog edit --- //
    dialogEditTextTitle: 'Editar categoría',
    dialodEditTextDescription: 'Edite la información a mostrar de la categoría en el listado.',
    // --- texts pagination --- //
    of: 'de',
    labels: {
      categoryNameEn: 'Nombre categoría (Inglés)',
      categoryNameSp: 'Nombre categoría (Español)',
      categoryType: 'Tipo categoría',
      colorType: 'Tipo de color',
      dInspectionCost: 'Costo de inspección'
    },
    tooltip: {
      addCategory: 'Añadir categoría',
      search: 'Buscar'
    },
    upload: {
      message: 'La categoría debe tener una imagén.',
      text: 'Imagén actual'
    },
    delete: {
      textDescription: '¿Estás seguro de eliminar la categoría? Al realizar esta acción no podrá revertirse'
    },
    searchbar:{
      search:'Nombre de categoría...',
      categoryType: 'Tipo de categoría',
      addCategory: 'Añadir categoría',

    },
    informationNotYetRegistered:'Información no registrada aún'

  }
};

export default texts;
