var texts = {
  en: {
    paginationRowPerPage: "Rows per page:",
    emptyText: 'No data to display.',
    dialogPlaceholderCategory: "Category",
    dialogPlaceholderSubcategory: "Subcategory",
    dialogPlaceholderArticleStatus: "Article status",
    dialogTitle: "Filters",
    dialogDescription:
      "Select the filter you want to apply to speed up your search.",
    dialogButtonCancel: "Cancel",
    dialogButtonApply: "Apply",
    searchbarButtonAdd: "Add",
    // --- texts dialog add --- //
    dialogAddProductNameEn: "Article name in english",
    dialogAddProductNameSp: "Article name in spanish",
    dialogAddProductDescriptionEn: "Article description in english",
    dialogAddProductDescriptionSp: "Article description in spanish",
    dialogAddTextTitle: "Add new item",
    dialogAddTextDescription:
      "Capture and add an image to show the new item in the listing.",
    dialogAddTextSubcatalog: "Subcatalog",
    dialogAddTextWarehouse: "Warehouse",
    dialogAddTextNumberBoxes: "Number of boxes",
    dialogAddTextUnitPrice: "Unit price",
    dialogAddTextUnitMeasure: "Unit of measurement",
    dialogAddTextProductAvailableTime: "Product availability time",
    dialogAddTextPreparationTime: "Preparation time",
    dialogAddTextLotNumber: "Batch id",
    dialogAddTextTransportationTemp: "Transportation temperature",
    dialogAddTextStorageTemp: "Storage temperature",
    dialogAddTextButtonCancel: "Cancel",
    dialogAddTextButtonAdd: "Add",
    // --- texts dialog edit --- //
    dialogEditTextButtonSave: "Save",
    dialogEditTextButtonCancel: "Cancel",
    dialogEditTextTitle: "Edit article",
    dialogEditTextDescription:
      "Edit the information to display for the article in the list.",
    // --- texts options menu --- //
    menuEditArticle: "Edit",
    menuDetailArticle: "Detail",
    menuDesactivateArticle: "Disabled",
    menuActivateArticle: "Enabled",
    menuDeleteArticle: "Delete",
    textButtonUpdatePrice: 'Update prices',
    textButtonListPrice: 'Create list price',
    textNameScreen: 'Items',
    // --- texts artcile detail --- //
    textAboutArticle: "About the article",
    textMinBoxes: "boxes",
    textAlbum: "Album",
    textCountry: "Country",
    textRegion: "Region",
    // --- texts return button --- //
    buttonTextReturn: "Go back",
    // -- texts pagination --- //
    of: "of",
    tooltip: {
      search: "Search",
      filter: "Filter",
    },
    // --- texts labels --- //
    labels: {
      productNameEn: "Article name (English)",
      productNameSp: "Article name (Spanish)",
      productDescriptionEn: "Article description (English",
      productDescriptionSp: "Article description (Spanish)",
      subcatalog: "Subcategory",
      warehouse: "Warehouse",
      numberBoxes: "Number boxes",
      unitPrice: "Unit price",
      unitMeasure: "Unit measure",
      productAvailableTime: "Product availability time",
      preparationTime: "Preparation time",
      lotNumber: "Lot id",
      transportationTemp: "Transportation temperature",
      storageTemp: "Storage temperature",
      filter: {
        category: "Category",
        subcategory: "Subcategory",
        statusArticle: "Article status",
      },
    },
    upload: {
      message: "The category must have an image.",
      text: "Current image",
    },
    delete: {
      title: "Delete item",
      description:
        "Are you sure to delete the article? By performing this action it cannot be reversed",
      note:
        "Note: Items attached to the vendor will go inactive, and completed orders will continue on their current intended course.",
      chkLabel: "I agree to delete the article and everything related to it.",
      cancel: "Cancel",
      delete: "Delete",
    },
    detailArticle: {
      viewSupplierArticle: "View supplier article",
      clasification: "Clasification",
      purchaseAmount: "Purchase amount",
      min: "Min",
      box: "Box",
      boxs: "Boxs",
      max: "Max",
      pricePerBox: "Price per ",
      descriptionOfTheArticle: "Item description",
      country: "Country",
      dMinAmountAllowed:"Minimum items for sale",
      warehouseUbication: "Warehouse ubication",
      temperatureInWarehouse: "Temperature in warehouse",
      transferTemperature: "Transfer temperature",
      preparationTime: "Preparation time",
      availableIn: "Item pickup availability date",
      unitOfMeasurement: "Unit of measurement",
      numberOf: "Number of ",
      perPallet: "Per pallet",
      approximateNumberOfPiecesPerPallet: "Aprox pieces per ",
      approximateBoxWeight: "Weight per ",
      attentionSchedule: "Attention schedule",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      mouseOverImageToZoom: "Hover over the image to zoom in.",
      lotNumber: "Lot number",
      State: "State",
      inventory: "Inventory",
      freightTable: "Freight table",
      download: "Download",
    },
    buttons: {
      delete: "Delete",
      toReturn: "Return",
      available: "Available",
      slow: "Paused",
    },
    searchbar: {
      search: "Article name...",
      category: "Category",
      subcategory: "Subcategory",
    },
  },
  sp: {
    paginationRowPerPage: "Filas por página:",
    emptyText: 'Sin datos para mostrar.',
    dialogPlaceholderCategory: "Categoría",
    dialogPlaceholderSubcategory: "Subcategoría",
    dialogPlaceholderArticleStatus: "Estatus del artículo",
    dialogTitle: "Filtros",
    dialogDescription:
      "Selecciona el filtro que deseas aplicar para agilizar tu búsqueda.",
    dialogButtonCancel: "Cancelar",
    dialogButtonApply: "Aplicar",
    searchbarButtonAdd: "Agregar",
    // --- texts dialog add --- //
    dialogAddProductNameEn: "Nombre del artículo en inglés",
    dialogAddProductNameSp: "Nombre del artículo en español",
    dialogAddProductDescriptionEn: "Descripción del artículo en inglés",
    dialogAddProductDescriptionSp: "Descripción del artículo en español",
    dialogAddTextTitle: "Agregar nuevo artículo",
    dialogAddTextDescription:
      "Capture y agregue una imagen para mostrar el nuevo artículo en el listado.",
    dialogAddTextSubcatalog: "Subcatálogo",
    dialogAddTextWarehouse: "Almacén",
    dialogAddTextNumberBoxes: "Número de cajas",
    dialogAddTextUnitPrice: "Precio unitario",
    dialogAddTextUnitMeasure: "Unidad de medida",
    dialogAddTextProductAvailableTime: "Tiempo de disponibilidad del artículo",
    dialogAddTextPreparationTime: "Tiempo de preparación",
    dialogAddTextLotNumber: "Id lot",
    dialogAddTextTransportationTemp: "Temperatura de transportación",
    dialogAddTextStorageTemp: "Temperatura de almacenamiento",
    dialogAddTextButtonCancel: "Cancelar",
    dialogAddTextButtonAdd: "Agregar",
    // --- texts dialog edit --- //
    dialogEditTextButtonSave: "Guardar",
    dialogEditTextButtonCancel: "Cancelar",
    dialogEditTextTitle: "Editar artículo",
    dialogEditTextDescription:
      "Edite la información a mostrar del artículo en el listado.",

    // --- texts options menu --- //
    menuEditArticle: "Editar",
    menuDetailArticle: "Detalle",
    menuDesactivateArticle: "Desactivar",
    menuActivateArticle: "Activar",
    menuDeleteArticle: "Eliminar",
    textButtonUpdatePrice: 'Actualizar precios',
    textButtonListPrice: 'Lista de precios',
    textNameScreen: "Artículos",
    // --- texts artcile detail --- //
    textAboutArticle: "Acerca del artículo",
    textMinBoxes: "Cajas",
    textAlbum: "Album",
    textCountry: "País",
    textRegion: "Región",
    // --- texts return button --- //
    buttonTextReturn: "Regresar",
    // -- texts pagination --- //
    of: "de",
    tooltip: {
      search: "Buscar",
      filter: "Filtrar",
    },
    // --- texts labels --- //,
    labels: {
      productNameEn: "Nombre artículo (Inglés)",
      productNameSp: "Nombre artículo (Español)",
      productDescriptionEn: "Descripción artículo (Inglés",
      productDescriptionSp: "Descripción artículo (Español)",
      subcatalog: "Subcategoría",
      warehouse: "Almacén",
      numberBoxes: "Número cajas",
      unitPrice: "Precio unitario",
      unitMeasure: "Unidad de medida",
      productAvailableTime: "Tiempo disponibilidad",
      preparationTime: "Tiempo preparación",
      lotNumber: "Id lot",
      transportationTemp: "Temperatura transportación",
      storageTemp: "Temperatura almacenamiento",
      filter: {
        category: "Categoría",
        subcategory: "Subcategoría",
        statusArticle: "Estatus de artículo∆",
      },
    },
    upload: {
      message: "La categoría debe tener una imagén.",
      text: "Imagén actual",
    },
    delete: {
      title: "Eliminar artículo",
      description:
        "¿Estás seguro de eliminar el artículo? Al realizar esta acción no podrá revertirse",
      note:
        "Nota: Los artículos adjuntos al proveedor pasarán a estado inactivo, y los pedidos concretados continuarán su curso actual previsto.",
      chkLabel:
        "Estoy de acuerdo con eliminar el artículo y todo lo relacionado a él.",
      cancel: "Cancelar",
      delete: "Eliminar",
    },
    detailArticle: {
      viewSupplierArticle: "Ver articulos del proveedor",
      clasification: "Clasificaciones",
      purchaseAmount: "Cantidad de compra",
      min: "Mínimo",
      box: "Caja",
      boxs: "Cajas",
      max: "Máximo",
      pricePerBox: "Precio por ",
      descriptionOfTheArticle: "Descripción del artículo",
      country: "País",
      dMinAmountAllowed:"Mínimo de articulos a la venta",
      warehouseUbication: "Ubicación del almacén",
      temperatureInWarehouse: "Temperatura en almacén",
      transferTemperature: "Temperatura en traslado",
      preparationTime: "Tiempo de preparación",
      availableIn: "Fecha de disponibilidad de recolección del artículo",
      unitOfMeasurement: "Unidad de medida",
      numberOf: "Número de",
      perPallet: "por pallet",
      approximateNumberOfPiecesPerPallet: "Piezas por ",
      approximateBoxWeight: "Peso por ",
      attentionSchedule: "Horarios de Atención",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      sunday: "Domingo",
      mouseOverImageToZoom:
        "Pasa el mouse encima de la imagen para aplicar zoom.",
      State: "Estado",
      lotNumber: "Número de lote",
      inventory: "Inventario",
      freightTable: "Tabulador de fletes",
      download: "Descargar",
    },
    buttons: {
      delete: "Eliminar",
      toReturn: "Regresar",
      available: "Disponible",
      slow: "Pausado",
    },
    searchbar: {
      search: "Nombre del artículo...",
      category: "Categoría",
      subcategory: "Subcategoría",
    },
  },
};

export default texts;
