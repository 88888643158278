import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'
// import redirect rules file
import reroute from '../reroute'

// import file that manages non-auth user routes
import router_guests from '@/router_guests'
// import file that manages auth user routes
import router_users from '@/router_users'

Vue.use(VueRouter)


var allRoutes = []
allRoutes = allRoutes.concat(router_guests, router_users)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
});



router.beforeEach((to, from, next) => {
  if (to.name == 'PreEntry') {
    if (Store.state.sToken !== '') {
      next({
        path: '/admin'
      })
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    // if goes to login screen redirect to admin
    if (to.name == 'HomeLogin') {
      if (Store.state.sToken !== '') {
        next({
          path: '/admin'
        })
      } else {
        next()
      }
    } else {
      // if user is logged in
      if (Store.state.sToken !== '') {
        // check user permission is administrator
        if (Store.state.permissions === null) {
          next()
        } else {
          // check if user is going to a route he has permission to access

          if (Store.state.permissions.map((e) => e.sPermissionId).includes(to.meta.permission, 0)) {
            next()
          } else {
            // if not redirect user to a route he does have permission to access
            next({
              path: reroute.redirectUser(Store.state.permissions.map((e) => e.sPermissionId))
            })

          }
        }
      } else {
        next({
          path: '/login'
        })
      }
    }
  }
})


export default router
