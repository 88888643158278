var texts = {
    en: {
        table: {
            headers: [
                {text:"Name", value:'AllsName',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Commission type", value:'AllCommissionsType',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Last update", value:'AllDate',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Actions", value:'actions',align: 'center',cellClass:'mon f-s14 color000000',class:'f-s14'},
              ],
        },
        exChangeRate:'Exchange rate',
        forUSD:'MXN for USD',
        amount:'Amount',
        usdPerPallet:'USD per pallet',
        porcentage:'Porcentage',
        pagination: {
            paginationRowPerPage: 'Rows per page',
            of: 'of'
        },
        dialogAddEdit: {
            title: 'Commission detail',
            exchangeRate: 'Exchange rate',
            exchangeRateHistory:'Exchange rate history',
            value:'Value',
            changeHistory:'Change history',
            currentValue:'Current value',
            bAlertExchangeRate:'Exchange rate must be greater than 0',
            buttons: {
                modify:'Modify',
                save: 'Save',
                cancel: 'Cancel'
            },
            header:{
                date:'Date',
                exchangeRate:'Exchange rate'
            }
        },
        tooltip: {
            search: 'Search',
        },
        informationNotYetRegistered:'Information not yet registered'

    },
    sp: {
        table: {
            headers: [
                {text:"Nombre", value:'AllsName',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Tipo de comisión", value:'AllCommissionsType',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Última actualización", value:'AllDate',cellClass:'mon f-s14 color000000',class:'f-s14'},
                {text:"Acciones", value:'actions',align: 'center',cellClass:'mon f-s14 color000000',class:'f-s14'},
              ],
        },
        forUSD:'MXN por USD',
        amount:'Tarifa',
        usdPerPallet:'USD por tarima',
        porcentage:'Porcentaje',
        exChangeRate:'Tipo de cambio',
        pagination: {
            paginationRowPerPage: 'Filas por página',
            of: 'de'
        },
        dialogAddEdit: {
            title: 'Detalle de la comisión',
            exchangeRate: 'Tipo de cambio',
            exchangeRateHistory:'Historial de tipo de cambio',
            value:'Valor',
            changeHistory:'Historial de cambios',
            currentValue:'Valor actual',
            modify:'Modificar',
            bAlertExchangeRate:'El tipo de cambio debe ser mayor que 0',
            buttons: {
                modify:'Modificar',
                save: 'Guardar',
                cancel: 'Cancelar'
            },
            header:{
                date:'Fecha',
                exchangeRate:'Tipo de cambio'
            }
        },
        tooltip: {
            search: 'Buscar',
        },
        informationNotYetRegistered:'Información no registrada aún'

    }
};

export default texts;