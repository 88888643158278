var texts = {
  en: {
    textTitle: 'Account registration',
    textDescription: 'Your personal information',
    fullName: 'Name',
    lastName: 'Last name',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    btnCreateAccount: 'Create account',
    btnCancel: 'Cancel',
    textValidationPassword: 'Minimum 8 characters'
  },
  sp: {
    textTitle: 'Registro de cuenta.',
    textDescription: 'Tus datos personales',
    fullName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo elctrónico',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    btnCreateAccount: 'Crear cuenta',
    btnCancel: 'Cancel',
    textValidationPassword: 'Mínimo 8 caracteres'
  }
};

export default texts;