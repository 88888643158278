import loginTexts from "@/texts/login";
import killSessionTexts from "@/texts/killSession";
import articleTexts from "@/texts/articles";
import categoryTexts from "@/texts/categories";
import subcategoryTexts from "@/texts/subcategories";
import subadminTexts from "@/texts/subadmins";
import deleteTexts from "@/texts/delete";
import globalDialogTexts from "@/texts/globaldialog";
import menuTexts from "@/texts/menu";
import navbarTexts from "@/texts/navbar";
import searchbarTexts from "@/texts/searchbar";
import registerTexts from "@/texts/register";
import passwordchangeTexts from "@/texts/passwordchange";
import passwordRecoveryTexts from "@/texts/passwordrecovery";
import emptyDataTexts from "@/texts/emptydata";
import customerTexts from "@/texts/customers";
import vendorTexts from "@/texts/vendors";
import uploadTexts from "@/texts/upload";
import ordersTexts from "@/texts/orders";
import claimsTexts from "@/texts/claims";
import commissionsTexts from "@/texts/commissions";
import detailuserTexts from "@/texts/detailuser";
import warehouseTexts from "@/texts/warehouse";
import filterTexts from "@/texts/filter";


export const fileTexts = {
  loginTexts,
  killSessionTexts,
  articleTexts,
  categoryTexts,
  subcategoryTexts,
  subadminTexts,
  deleteTexts,
  globalDialogTexts,
  menuTexts,
  navbarTexts,
  searchbarTexts,
  registerTexts,
  passwordRecoveryTexts,
  emptyDataTexts,
  customerTexts,
  vendorTexts,
  uploadTexts,
  ordersTexts,
  claimsTexts,
  passwordchangeTexts,
  commissionsTexts,
  detailuserTexts,
  warehouseTexts,
  filterTexts
}