import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import vuetify from "@/plugins/vuetify";
import { mixs } from "@/mixins";
import { fileTexts } from "@/files";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/globalComponents";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import * as VueGoogleMaps from "vue2-google-maps";
import { functionsIn } from "lodash";
import "dropzone-vue/dist/dropzone-vue.common.css";
import VueImgOrientationChanger from "vue-img-orientation-changer";
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

 
Vue.use(Datetime)
Vue.use(Vuesax, {
  // options here
})

Vue.use(VueImgOrientationChanger);

//Import of mixins for the alert call
Vue.mixin(mixs);

//Use of alerts throughout the project
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0",
    libraries: "places",
  },
});

//Configure production tip
Vue.config.productionTip = false;

// initiate axios as a window variable for convinience ( ex: DB.get ; DB.post ; DB.put ; DB.delete )
window.DB = axios;

//Import of project text files
window.FILE = fileTexts;



// initailize URI for global use
// window.URI = "http://192.168.1.21:3000";
// window.URI = 'http://ec2-18-219-4-181.us-east-2.compute.amazonaws.com'

// initailize URI for global use
//qa --- dev --- alfa
//window.URI = 'https://api.proplat.com/alfa'
window.URI = 'https://api.proplat.com/alfa'
// window.URI = 'http://localhost:3000/alfa'

window.FORMAT_MONEY = function(number) {
  let convert_number = Number(number).toFixed(2);
  return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

window.FERROR403 = function(status) {};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
