var texts = {
  en: {
    table: {
      headersInProcess: [
        {
          text: "Sale order",
          value: "sSalesOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Purchase order",
          value: "sPurchaseOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Vendor",
          value: "sVendorName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Customer",
          value: "sCustomerName",
          cellClass: "mon f-s14 color000000 ",
          class: "f-s14",
        },
        {
          text: "Dispute status",
          value: "sStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Manager",
          value: "sAdminOwnerName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      headersFinaly: [
        {
          text: "Sales order",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Purchase order",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Supplier",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      // headersFinaly : ['Sale order','Purchase order','Supplier','Actions'],
      actions: {
        labels: {
          textEdit: "Edit",
          textActivate: "Enabled",
          textDeactivate: "Disabled",
          textRecoveryPassword: "Reset password",
          textDelete: "Delete",
          textDetails: "Detail",
        },
      },
      status: {
        textActivate: "Enabled",
        textDeactivate: "Disabled",
      },
    },
    pagination: {
      paginationRowPerPage: "Rows per page",
      of: "of",
    },
    dialogDelete: {
      textTitle: "Customers",
    },
    dialogEdit: {
      title: "Update dispute",
      availableStates: "available status",
      selectAState: "select a status",
      writeResolution: "Write a description for this dispute...",

      buttons: {
        upgrade: "upgrade",
        toRetrun: "to",
      },
      labels: {
        latitude: "Latitude",
        longitude: "Longitude",
        blueBook: "Blue book Id",
        description: "Description",
        email: "Email",
        address: "Address",
        city: "City",
        zipCode: "Zip code",
        name: "Name",
        license: "PACA license",
        phoneNumber: "Phone number",
        state: "State",
        taxPayerId: "Tax payer id",
        country: "Country",
      },
    },
    tooltip: {
      search: "Search",
    },
    tabsItems: {
      inProcess: "In process",
      finished: "Finished",
    },
    detailClaims: {
      toRetrun: "Return",
      disputeDetail: "Dispute detail",
      creationOfTheDispute: "Creation of the dispute",
      disputeEnding: "dispute finalized",
      status: "Status",
      update: "Update",
      payment: "Payment",
      salesOrder: "Sales order",
      orderTotal: "Order total",
      vendor: "Supplier",
      originWarehouse: "Origin warehouse",
      destinationWarehouse: "Destination warehouse",
      customerWarehouse: "Customer warehouse",
      borderWarehouse: "PROPLAT warehouse",
      customer: "Customer",
      nameOfTheWarehouseManager: "Name of the warehouse manager",
      phone: "Phone",
      email: "Email",
      disputeDetail: "Dispute detail",
      disputeID: "Disputes ID",
      creationDate: "Creation date",
      categoryOfDispute: "Category of dispute",
      subcategoryOfTheDispute: "Subcategory of the dispute",
      disputeManager: "Dispute manager",
      descriptionOfDispute: "Description of the dispute",
      sResolutionMessage: "Resolution",
      sNotResolutionMessage: "This dispute has not yet been resolved."
    },
    searchbar: {
      search: "Search by sales order",
      manager: "Manager",
      startDate: "Start date",
      finalDate: "Final date",
    },
    informationNotYetRegistered: "Information not yet registered",
  },
  sp: {
    table: {
      headersInProcess: [
        {
          text: "Pedido de venta",
          value: "sSalesOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Orden de compra",
          value: "sPurchaseOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Proveedor",
          value: "sVendorName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Comprador",
          value: "sCustomerName",
          cellClass: "mon f-s14 color000000 ",
          class: "f-s14",
        },
        {
          text: "Estado de la disputa",
          value: "sStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Encargado",
          value: "sAdminOwnerName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      // headersInProcess: ['Pedido de venta','Orden de compra','Proveedor','Comprador','Estado de la disputa', 'Encargado','Acciones'],
      // headersFinaly : ['Pedido de venta', 'Orden de compra', 'Proveedor','Acciones'],
      headersFinaly: [
        {
          text: "Pedido de venta",
          value: "sSalesOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Orden de compra",
          value: "sPurchaseOrderFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Proveedor",
          value: "sVendorName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      actions: {
        labels: {
          textEdit: "Editar",
          textActivate: "Activar",
          textDeactivate: "Desactivar",
          textRecoveryPassword: "Recuperar contraseña",
          textDelete: "Eliminar",
          textDetails: "Detalle",
        },
      },
      status: {
        textActivate: "Activado",
        textDeactivate: "Desactivado",
      },
    },
    pagination: {
      paginationRowPerPage: "Filas por página",
      of: "de",
    },
    dialogDelete: {
      textTitle: "Compradores",
    },
    dialogEdit: {
      title: "Actualización de disputa",
      availableStates: "Estados disponibles",
      selectAState: "Seleccionar un estado…",
      writeResolution: "Escribir descripción",
      buttons: {
        upgrade: "Actualizar",
        toRetrun: "Regresar",
      },
      labels: {
        latitude: "Latitud",
        longitude: "Longitud",
        blueBook: "Id blue book",
        description: "Descripción",
        email: "Correo electrónico",
        address: "Dirección",
        city: "Ciudad",
        zipCode: "Código postal",
        name: "Nombre",
        license: "Lincencia PACA",
        phoneNumber: "Número telefónico",
        state: "Estado",
        taxPayerId: "Id tax payer",
        country: "País",
      },
    },
    tooltip: {
      search: "Buscar",
    },
    tabsItems: {
      inProcess: "En proceso",
      finished: "Finalizados",
    },
    detailClaims: {
      toRetrun: "Regresar",
      disputeDetail: "Detalle de disputa",
      creationOfTheDispute: "Creación de la disputa",
      disputeEnding: "Finalización de la disputa",
      status: "Estado",
      update: "Actualizar",
      payment: "Pago",
      salesOrder: "Pedido de venta",
      orderTotal: "Total de la orden",
      vendor: "Proveedor",
      originWarehouse: "Almacén origen",
      destinationWarehouse: "Almacén destino",
      customerWarehouse: "Almacén cliente",
      borderWarehouse: "Almacén PROPLAT",
      customer: "Comprador",
      nameOfTheWarehouseManager: "Nombre del encargado del almacén",
      phone: "Teléfono",
      email: "Correo electrónico",
      disputeDetail: "Detalle de la disputa",
      disputeID: "ID de la disputa",
      creationDate: "Fecha de creación",
      categoryOfDispute: "Categoría de la disputa",
      subcategoryOfTheDispute: "Subcategoría de la disputa",
      disputeManager: "Encargado de la disputa",
      descriptionOfDispute: "Descripción de la disputa",
      sResolutionMessage: "Resolución",
      sNotResolutionMessage: "Esta disputa no ha sido resuelta aún."

    },
    searchbar: {
      search: "Buscar por pedido de venta",
      manager: "Encargado",
      startDate: "Fecha inicio",
      finalDate: "Fecha final",
    },
    informationNotYetRegistered: "Información no registrada aún",
  },
};

export default texts;
