
const routes = [
  {
    path: '/admin',
    name: 'Home',
    redirect: '/admin/orders',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'orders',
        name: 'Orders',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '19feae76-e1df-4dc5-ba52-4a2ffbaba896'
        },
        component: () => import('@/views/Admin/Orders/config.vue'),
      },
      {
        path: 'order-detail/:id',
        name: 'OrderDetail',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '19feae76-e1df-4dc5-ba52-4a2ffbaba896'
        },
        component: () => import('@/views/Admin/OrderDetail/config.vue'),
      },
      {
        path: 'claims',
        name: 'Claims',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '2334b4e0-ce21-41b1-ad22-0195bc13bea4'
        },
        component: () => import('@/views/Admin/Claims/config.vue'),
      },
      {
        path: 'claims-detail/:id',
        name: 'ClaimsDetails',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '2334b4e0-ce21-41b1-ad22-0195bc13bea4'
        },
        component: () => import('@/views/Admin/ClaimsDetails/config.vue'),
      },
      {
        path: 'customers',
        name: 'Customers',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '257bc8cb-678f-4665-b239-9728735104ee'
        },
        component: () => import('@/views/Admin/Customers/config.vue'),
      },
      {
        path: 'customer-detail/:id',
        name: 'CustomersDetails',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '257bc8cb-678f-4665-b239-9728735104ee'
        },
        component: () => import('@/views/Admin/CustomersDetails/config.vue'),
      },
      {
        path: 'vendors',
        name: 'Vendors',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '88bea0cf-71b9-4d09-a334-755d88f4192c'
        },
        component: () => import('@/views/Admin/Vendors/config.vue'),
      },
      {
        path: 'vendor-detail/:id',
        name: 'VendorsDetails',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '88bea0cf-71b9-4d09-a334-755d88f4192c'
        },
        component: () => import('@/views/Admin/VendorsDetails/config.vue'),
      },
      {
        path: 'subadmins',
        name: 'Subadmins',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/views/Admin/Subadmins/config.vue'),
      },
      {
        path: 'articles',
        name: 'Articles',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '0ac1f663-3ca6-47cf-b01c-6942b868067d'
        },
        component: () => import('@/modules/articles/pages/ListPage.vue'),
      },
      {
        path: 'ProplatCalculator',
        name: 'ProplatCalculator',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/modules/proplatCalculator/pages/CalculatorPage.vue'),
      },
      {
        path: 'shoppingCart',
        name: 'ShoppingCart',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/modules/shoppingCart/pages/CartPage.vue'),
      },
      {
        path: 'freightPrices',
        name: 'FreightPrices',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/modules/freightPrices/pages/ListPage.vue'),
      },
      {
        path: 'logistic',
        name: 'Logistic',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/modules/logistics/pages/ListPage.vue'),
      },
      {
        path: 'logistic/:sFreightId',
        name: 'LogisticDetail',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'null'
        },
        component: () => import('@/modules/logistics/pages/DetailFreight.vue'),
      },
      {
        path: 'article-detail/:sProductId',
        name: 'ArticleDetail',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '0ac1f663-3ca6-47cf-b01c-6942b868067d'
        },
        component: () => import('@/views/Admin/ArticleDetail/config.vue'),
      },
      {
        path: 'categories',
        name: 'Categories',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971'
        },
        component: () => import('@/views/Admin/Categories/config.vue'),
      },
      {
        path: 'subcategories',
        name: 'Subcategories',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971'
        },
        component: () => import('@/views/Admin/Subcategories/config.vue'),
      },
      {
        path: 'commissions',
        name: 'Commissions',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: 'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c'
        },
        component: () => import('@/views/Admin/Commissions/config.vue'),
      },
      {
        path: 'warehouse',
        name: 'Warehouse',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '3d681ddc-160c-4e73-b3e1-0ef07840563a'
        },
        component: () => import('@/views/Admin/Warehouse/config.vue'),
      },
      {
        path: 'warehouse-detail/:id',
        name: 'WarehouseDetail',
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: '3d681ddc-160c-4e73-b3e1-0ef07840563a'
        },
        component: () => import('@/views/Admin/WarehouseDetail/config.vue'),
      },
    ],
    component: () => import('@/App'),
  }
]

export default routes
