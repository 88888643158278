var texts = {
  en: {
    msgButtonSearchbar: 'Add',
    textTitleModal: 'Add new subcategory',
    textDescriptionModal: 'Capture and add an image to display the new subcategory in the listing.',
    placeholderSubcategoryNameEn: 'Subcategory name in english',
    placeholderSubcategoryNameEs: 'Subcategory name in spanish',
    paginationRowPerPage: 'Rows per page:',
    modalButtonAdd: 'Add',
    modalButtonCancel: 'Cancel',
    textSelectCategory: 'Select the category where the new subcategory will be.',
    placeholderCategorySelect: 'Category.',
    textDialogDelete: 'Delete subcategory',
    modalButtonEdit: 'Save',
    // --- texts dialog edit --- //
    dialogEditTextTitle: 'Edit sub category',
    dialogEditTextDescription: 'Edit the information to display for the sub category in the list.',
    // --- texts pagination --- //
    of: 'of',
    tooltip: {
      addCategory: 'Add Subcategory',
      search: 'Search'
    },
    labels: {
      subcategoryNameEn: 'Subcategory name (English)',
      subcategoryNameSp: 'Subcategory name (Spanish)',
      category: 'Category'
    },
    upload: {
      message: 'The category must have an image.',
      text: 'Current image'
    },
    delete: {
      textDescription: 'Are you sure to delete the subcategory? By performing this action, it cannot be reversed'
    },
    searchbar:{
      search:'Subcategory name...',
      categoryType: 'Category type ',
      addSubcategory: 'Add subcategory',
    }
  },
  sp: {
    msgButtonSearchbar: 'Agregar',
    textTitleModal: 'Agregar nueva subcategoría',
    textDescriptionModal: 'Capture y agregue una imagen para mostrar la nueva subcategoría en el listado.',
    placeholderSubcategoryNameEn: 'Nombre de la subcategoría en inglés',
    placeholderSubcategoryNameEs: 'Nombre de la subcategoría en español',
    paginationRowPerPage: 'Filas por página:',
    modalButtonAdd: 'Agregar',
    modalButtonCancel: 'Cancelar',
    textSelectCategory: 'Selecciona la categoría donde estara la nueva subcategoría.',
    placeholderCategorySelect: 'Categoría.',
    textDialogDelete: 'Eliminar subcategoría',
    modalButtonEdit: 'Guardar',
    // --- texts dialog edit --- //
    dialogEditTextTitle: 'Editar subcategoría',
    dialogEditTextDescription: 'Edite la información a mostrar de la subcategoría en el listado..',
    // --- texts pagination --- //
    of: 'de',
    tooltip: {
      addCategory: 'Agregar subcategoría',
      search: 'Buscar'
    },
    labels: {
      subcategoryNameEn: 'Nombre subcategoría (Inglés)',
      subcategoryNameSp: 'Nombre subcategoría (Español)',
      category: 'Categoría'
    },
    upload: {
      message: 'La categoría debe tener una imagén.',
      text: 'Imagén actual'
    },
    delete: {
      textDescription: '¿Estás seguro de eliminar la subcategoría? Al realizar esta acción no podrá revertirse'
    },
    searchbar:{
      search:'Nombre de subcategoría...',
      categoryType: 'Tipo de categoría ',
      addSubcategory: 'Agregar subcategoría',
    }
  }
};

export default texts;