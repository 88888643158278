import Vue from 'vue'


// --- reusable components --- //
Vue.component('LanguageComponent', () => import('@/components/GeneralComponents/Language/config'))
Vue.component('EmptyData', () => import('@/components/GeneralComponents/EmptyData/config.vue'))
Vue.component('Navbar', () => import('@/components/GeneralComponents/Navbar/config.vue'))
Vue.component('Sidebar', () => import('@/components/GeneralComponents/Sidebar/config.vue'))
Vue.component('DialogDelete', () => import('@/components/GeneralComponents/Delete/config.vue'))
Vue.component('GlobalDialog', () => import('@/components/GeneralComponents/GlobalDialog/config.vue'))
Vue.component('DialogPasswordChange', () => import('@/components/GeneralComponents/PasswordChange/config.vue'))
Vue.component('UploadImage', () => import('@/components/GeneralComponents/Upload/config.vue'))
Vue.component('MenuComponent', () => import('@/components/GeneralComponents/Menu/config.vue'))
Vue.component('LogoComponent', () => import('@/components/GeneralComponents/Logo/config.vue'))
Vue.component('InputColorPicker', () => import('vue-native-color-picker'))
Vue.component('FilterComponent', () => import('@/components/GeneralComponents/Filter/config.vue'))
Vue.component('VuePhotoZoomPro', () => import('vue-photo-zoom-pro'))
Vue.component('GoogleMap', () => import('@/components/GeneralComponents/Map/config.vue'))
Vue.component('autoCompleteMapComponent', () => import('@/components/GeneralComponents/autoCompleteMap/config.vue'))
Vue.component('killSession', () => import('@/components/GeneralComponents/killSession/config.vue'))
Vue.component('Uploader', () => import('vux-uploader-component'))
Vue.component('DropZone', () => import('dropzone-vue'))
Vue.component('PoweredBy', () => import('@/components/GeneralComponents/PoweredBy/config.vue'))
Vue.component('activeArticleComponent', () => import('@/components/GeneralComponents/activeArticle/config.vue'))
Vue.component('InputCurrencyGlobal', () => import('@/components/GeneralComponents/input_currency_global/InputCurrencyGlobal.vue'))
Vue.component('GalleryComponent', () => import('@/components/GeneralComponents/Gallery/config.vue'));
Vue.component('VTextFieldCurrency', () => import('@/components/GeneralComponents/v-text-field-currency/v-text-field-currency.vue'));







// --- Login Components --- //
Vue.component('LoginForm', () => import('@/components/Login/config.vue'))

// --- Register Components --- //
Vue.component('RegisterForm', () => import('@/components/Register/config.vue'))

// --- Password Recovery Components --- //
Vue.component('PasswordRecoveryForm', () => import('@/components/PasswordRecovery/config.vue'))

// --- vendors components --- //
Vue.component('VendorSearchbar', () => import('@/components/Admin/Vendors/Searchbar/config.vue'))
Vue.component('VendorContent', () => import('@/components/Admin/Vendors/Content/config.vue'))
Vue.component('VendorDialogEdit', () => import('@/components/Admin/Vendors/dialogs/edit/config.vue'))

// --- subcategories components --- //
Vue.component('SubcategorySearchbar', () => import('@/components/Admin/Subcategories/Searchbar/config.vue'))
Vue.component('SubcategoryContent', () => import('@/components/Admin/Subcategories/Content/config.vue'))
Vue.component('SubcategoryDialogAdd', () => import('@/components/Admin/Subcategories/Dialogs/Add/config.vue'))
Vue.component('SubcategoryDialogEdit', () => import('@/components/Admin/Subcategories/Dialogs/Edit/config.vue'))

// --- subadmin components --- //
Vue.component('SubadminSearchbar', () => import('@/components/Admin/Subadmins/Searchbar/config.vue'))
Vue.component('SubadminContent', () => import('@/components/Admin/Subadmins/Content/config.vue'))
Vue.component('SubadminDialogAdd', () => import('@/components/Admin/Subadmins/Dialogs/Add/config.vue'))
Vue.component('SubadminDialogEdit', () => import('@/components/Admin/Subadmins/Dialogs/Edit/config.vue'))
Vue.component('SubadminDialogDetail', () => import('@/components/Admin/Subadmins/Dialogs/DetailAdmin/config.vue'))
Vue.component('PermitsDialogDetail', () => import('@/components/Admin/Subadmins/Dialogs/AddPermits/config.vue'))

// --- subadmin detail components --- //
Vue.component('SubadminDetailContent', () => import('@/components/Admin/SubadminDetail/Content/config.vue'))
Vue.component('SubadminDetailSearchbar', () => import('@/components/Admin/SubadminDetail/Searchbar/config.vue'))

// --- order components --- //
Vue.component('OrderSearchbar', () => import('@/components/Admin/Orders/Searchbar/config.vue'))
Vue.component('OrderContent', () => import('@/components/Admin/Orders/Content/config.vue'))

// --- order detail components --- //
Vue.component('OrderDetailSearchbar', () => import('@/components/Admin/OrderDetail/Searchbar/config.vue'))
Vue.component('OrderDetailContent', () => import('@/components/Admin/OrderDetail/Content/config.vue'))
Vue.component('DialogUpdateStatusComponent', () => import('@/components/Admin/OrderDetail/Dialogs/UpdateStatus/config.vue'))
Vue.component('DialogConfirmPayMethodComponent', () => import('@/components/Admin/OrderDetail/Dialogs/ConfirmPayMethod/config.vue'))
Vue.component('DialogConfirmDateArrivalComponent', () => import('@/components/Admin/OrderDetail/Dialogs/ConfirmDateArrival/config.vue'))
Vue.component('DialogEditFileComponent', () => import('@/components/Admin/OrderDetail/Dialogs/editFile/config.vue'))
Vue.component('DialogAddProductsComponent', () => import('@/components/Admin/OrderDetail/Dialogs/addProducts/config.vue'))
Vue.component('DialogChangePaymentMethodComponent', () => import('@/components/Admin/OrderDetail/Dialogs/ChangePayMethod/config.vue'))




// --- customer components --- //
Vue.component('CustomerSearchbar', () => import('@/components/Admin/Customers/Searchbar/config.vue'))
Vue.component('CustomerContent', () => import('@/components/Admin/Customers/Content/config.vue'))
Vue.component('CustomerDialogEdit', () => import('@/components/Admin/Customers/Dialogs/Edit/config.vue'))

// --- customer details components --- //
Vue.component('CustomerDetailSearchbar', () => import('@/components/Admin/CustomersDetails/Searchbar/config.vue'))
Vue.component('CustomerDetailContent', () => import('@/components/Admin/CustomersDetails/Content/config.vue'))
Vue.component('CustomerDetailDialogWarehouse', () => import('@/components/Admin/CustomersDetails/Dialogs/DetailsWarehouse/config.vue'))
Vue.component('CustomerDetailDialogUser', () => import('@/components/Admin/CustomersDetails/Dialogs/DetailUser/config.vue'))

// --- vendor details components --- //
Vue.component('VendorDetailSearchbar', () => import('@/components/Admin/VendorsDetails/Searchbar/config.vue'))
Vue.component('VendorDetailContent', () => import('@/components/Admin/VendorsDetails/Content/config.vue'))
Vue.component('VendorDialogDetailArticle', () => import('@/components/Admin/VendorsDetails/Dialogs/DetailArticle/config.vue'))
Vue.component('GlobalDetailDialogWarehouse', () => import('@/components/GeneralComponents/DetailsWareHouse/config.vue'))
Vue.component('GlobalDetailDialogUser', () => import('@/components/GeneralComponents/DetailUser/config.vue'))

// --- Claims components --- //
Vue.component('ClaimsSearchbar', () => import('@/components/Admin/Claims/Searchbar/config.vue'))
Vue.component('ClaimsContent', () => import('@/components/Admin/Claims/Content/config.vue'))

// --- Claims details components --- //
Vue.component('ClaimsDetailsSearchbar', () => import('@/components/Admin/ClaimsDetails/Searchbar/config.vue'))
Vue.component('ClaimsDetailsContent', () => import('@/components/Admin/ClaimsDetails/Content/config.vue'))
Vue.component('ClaimsDetailsContentSecond', () => import('@/components/Admin/ClaimsDetails/ContentSecond/config.vue'))
Vue.component('DialogUpdateStatusClaimsComponent', () => import('@/components/Admin/ClaimsDetails/Dialogs/UpdateStatus/config.vue'))




// --- categories components --- //
Vue.component('CategorySearchbar', () => import('@/components/Admin/Categories/Searchbar/config.vue'))
Vue.component('CategoryContent', () => import('@/components/Admin/Categories/Content/config.vue'))
Vue.component('CategoryDialogAdd', () => import('@/components/Admin/Categories/Dialogs/Add/config.vue'))
Vue.component('CategoryDialogEdit', () => import('@/components/Admin/Categories/Dialogs/Edit/config.vue'))

// --- articles components --- //
Vue.component('ArticleSearchbar', () => import('@/components/Admin/Articles/Searchbar/config.vue'))
Vue.component('ArticleContent', () => import('@/components/Admin/Articles/Content/config.vue'))
Vue.component('ArticleDialogAdd', () => import('@/components/Admin/Articles/Dialogs/Add/config.vue'))
Vue.component('ArticleDialogEdit', () => import('@/components/Admin/Articles/Dialogs/Edit/config.vue'))
Vue.component('ArticleDialogFilter', () => import('@/components/Admin/Articles/Dialogs/Filter/config.vue'))

// --- articles details components --- //
Vue.component('ArticleDetailSearchbar', () => import('@/components/Admin/ArticleDetail/Searchbar/config.vue'))
Vue.component('ArticleDetailContent', () => import('@/components/Admin/ArticleDetail/Content/config.vue'))

// --- commissions components --- //
Vue.component('CommissionsSearchbar', () => import('@/components/Admin/Commissions/Searchbar/config.vue'))
Vue.component('CommissionsContent', () => import('@/components/Admin/Commissions/Content/config.vue'))
Vue.component('CommissionsDialogAddEdit', () => import('@/components/Admin/Commissions/Dialogs/Add/config.vue'))

// --- warehouse components --- //
Vue.component('WarehouseSearchbar', () => import('@/components/Admin/Warehouse/Searchbar/config.vue'))
Vue.component('WarehouseContent', () => import('@/components/Admin/Warehouse/Content/config.vue'))
Vue.component('WarehouseDialogAdd', () => import('@/components/Admin/Warehouse/Dialogs/Add/config.vue'))

// --- warehouse components --- //
Vue.component('WarehouseDetailSearchbar', () => import('@/components/Admin/WarehouseDetail/Searchbar/config.vue'))
Vue.component('WarehouseDetailContent', () => import('@/components/Admin/WarehouseDetail/Content/config.vue'))
Vue.component('WarehouseDetailDialogAddDocument', () => import('@/components/Admin/WarehouseDetail/Dialogs/AddDocument/config.vue'))