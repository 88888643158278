var texts = {
  en: {
    orders: {
      title: 'Orders',
    },
    orderDetail: {
      title: 'Order detail',
    },
    claims: {
      title: 'Disputes'
    },
    customers: {
      title: 'Customers'
    },
    customersDetails: {
      title: 'Customers details'
    },
    vendors: {
      title: 'Supplier'
    },
    vendorsDetails: {
      title: 'Provider company detail'
    },
    subadmins: {
      title: 'Administrators'
    },
    subadminDetail: {
      title: 'Sub Admin Detail'
    },
    articles: {
      title: 'Items'
    },
    categories: {
      title: 'Categories'
    },
    subcategories: {
      title: 'Subcategories'
    },
    articleDetail: {
      title: 'Article detail'
    },
    commissions: {
      title: 'Commissions'
    },
    warehouse : {
      title:'My warehouses'
    }
  },
  sp: {
    orders: {
      title: 'Pedidos',
    },
    orderDetail: {
      title: 'Detalle del pedido',
    },
    claims: {
      title: 'Disputas'
    },
    customers: {
      title: 'Compradores'
    },
    customersDetails: {
      title: 'Detalle de compradores'
    },
    vendors: {
      title: 'Proveedores'
    },
    vendorsDetails: {
      title: 'Detalle de empresa proveedora'
    },
    subadmins: {
      title: 'Administradores'
    },
    subadminDetail:{
      title:'Detalle Sub Admin'
    },
    articles: {
      title: 'Artículos'
    },
    categories: {
      title: 'Categorías'
    },
    subcategories: {
      title: 'Subcategorías'
    },
    articleDetail: {
      title: 'Detalle del artículo'
    },
    commissions:{
      title:'Comisiones'
    },
    warehouse : {
      title:'Mis almacenes'
    }
  }
};

export default texts;