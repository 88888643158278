var texts = {
  en: {
    title: '¡Welcome!',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    forgotPassword: 'Did you forgot your password?',
    buttonPasswordRecovery: 'Change password',
  },
  sp: {
    title: '¡Bienvenido!',
    email: 'Correo electrónico',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    forgotPassword: '¿Olvidaste tu contraseña?',
    buttonPasswordRecovery: 'Cambiar contraseña',
  }
};

export default texts;